import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, ReplaySubject, Subject, zip} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GeometryService} from '../services/geometry.service';
import {UserConfigService} from '../services/user-config.service';
import PrintTask from '@arcgis/core/tasks/PrintTask';
import PrintTemplate from '@arcgis/core/rest/support/PrintTemplate';

@Component({
  selector: 'app-save-map-button',
  templateUrl: './save-map-button.component.html',
  styleUrls: ['./save-map-button.component.css']
})
export class SaveMapButtonComponent {
  @Input() view: ReplaySubject<any>; // escape hatch used b/c toJSON isn't defined in type declaration (but exists)
  saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public geometryService: GeometryService = new GeometryService();

  constructor(public http: HttpClient, public userConfig: UserConfigService) {
  }

  saveMap() {
    this.saving.next(true);

    const print_task = new PrintTask();
    this.view.pipe(
      switchMap(view => {
        const template = new PrintTemplate();
        const map_data_json = view.map.toJSON();
        // @ts-ignore
        const opslayer_promise = print_task._createOperationalLayers(view, template).then(function (operation_layers) {
          map_data_json['operationalLayers'] = operation_layers;
          view.map.allLayers.items.forEach((layer) => {
            map_data_json['operationalLayers'].forEach((inner_layer, index) => {
              if (layer.id === inner_layer.id) {
                map_data_json['operationalLayers'][index]['type'] = layer.operationalLayerType;
                map_data_json['operationalLayers'][index]['visible'] = layer.visible;
              }
            });
          });
          return map_data_json;
        }).catch(function (err) {
          console.log(err);
        });
        // print_task._getPrintDefinition(map, {}).then(function (webmap_json) {
        //   console.log(webmap_json);
        // });
        // portalItem['token'] = this.loginService.esri_token_object.token;
        // return this.http.post(
        //   `https://epa.maps.arcgis.com/sharing/rest/content/users/${this.loginService.esri_token_object.userId}/addItem`,
        //   portalItem,
        //   {
        //     headers: new HttpHeaders().set('Authorization', `Bearer ${this.loginService.esri_token_object.token}`),
        //     withCredentials: true
        //   });

        const projection_obs = this.geometryService.project(view.extent, 4326);
        return zip(opslayer_promise, projection_obs, this.userConfig.config);
      }),
      switchMap(([map_data_json, extent, user_config]) => {
        map_data_json['extent'] = (extent as any).toJSON();
        if (user_config.config.default_webmap && user_config.config.default_webmap !== environment.default_base_map) {
          map_data_json['id'] = user_config.config.default_webmap;
        }
        return this.http.post(`/save_web_map/`, map_data_json).pipe(tap(response => {
          user_config.config.default_webmap = response['id'];
          this.saving.next(false);
        }));
      })).subscribe();

  }
}
