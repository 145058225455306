<div class="dashboard-container">
    <mat-card>
      <mat-card-title>Feature Classes</mat-card-title>
      <mat-card-content>
        Total: {{ total_feature_classes | async }}<br/>
        Have Abstract: {{ (total_fc_with_abstract | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
        Have Metadata UUID:
        {{ (total_fc_with_uuid | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
        Have WAF XML: {{ (total_fc_with_waf | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
        Have EDG Record: {{ (total_fc_with_edg | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
        In use in Map Service:
        {{ (total_fc_with_map | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
        In use in GeoPlatform:
        {{ (total_fc_with_agol | async) / (total_feature_classes | async) | percent:'1.0-0' }}<br/>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>GeoPlatform</mat-card-title>
      <mat-card-content>
        Total Items: {{ total_agol | async }}<br/>
        Total Web Maps: {{ total_agol_web_maps | async }}<br/>
        Web Maps Using R9/ER Cloud Map Service*:
        {{ (total_agol_with_map | async) / (total_agol_web_maps | async) | percent:'1.1-3' }}<br/>
        Web Maps Using R9/ER Cloud Library*:
        {{ (total_agol_with_fc | async) / (total_agol_web_maps | async) | percent:'1.1-3' }}
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Map Services</mat-card-title>
      <mat-card-content>
        Total: {{ total_map_services | async }}<br/>
        Using R9 Library: {{ (total_map_with_fc | async) / (total_map_services | async) | percent:'1.0-0' }}<br/>
        In use in GeoPlatform: {{ (total_map_with_agol | async) / (total_map_services | async) | percent:'1.0-0' }}
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Region 9 Services</mat-card-title>
      <mat-card-content>
        <div style="padding-bottom: 15px;">Internal R9 Services:<br/>
          <a target="_blank" href="https://gis.r09.epa.gov/arcgis/rest/services">https://gis.r09.epa.gov/arcgis/rest/services</a>
        </div>
        <div style="padding-bottom: 15px;">Internal R9 Portal:<br/>
          <a target="_blank" href="https://gis.r09.epa.gov/portal">https://gis.r09.epa.gov/portal</a>
        </div>
        <div style="padding-bottom: 15px;">R9 ER Cloud (Tech Center):<br/>
          <a target="_blank" href="https://r9data.response.epa.gov/epar9gis2/rest/services">https://r9data.response.epa.gov/epar9gis2/rest/services</a>
        </div>
        <div style="padding-bottom: 15px;">R9 ER Cloud (START):<br/>
          <a target="_blank" href="https://r9data.response.epa.gov/epar9gis/rest/services">https://r9data.response.epa.gov/epar9gis/rest/services</a>
        </div>
        <div style="padding-bottom: 15px;">R9 ER Cloud (AUM):<br/>
          <a target="_blank" href="https://r9data.response.epa.gov/naum/rest/services">https://r9data.response.epa.gov/naum/rest/services</a>
        </div>
        <div style="padding-bottom: 15px;">NCC:<br/>
          <a target="_blank" href="https://geopub.epa.gov/arcgis/rest/services">https://geopub.epa.gov/arcgis/rest/services</a>
        </div>
      </mat-card-content>
    </mat-card>
</div>
