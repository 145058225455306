<div style="display: flex; flex-direction: column; justify-content: space-around">
  <form novalidate [formGroup]="dataIntakeForm" style="width: 100%;">
    <button mat-raised-button color="primary" id="file_button" style="margin-bottom: 22px;">Attach File</button>
    <span style="padding-left: 15px;" class="mat-body-strong">{{filename}}</span>

    <!--        <mat-form-field>-->
    <!--          <input matInput type="text" formControlName="display_location" placeholder="Location" readonly>-->
    <!--        </mat-form-field>-->
    <!--        <mat-form-field>-->
    <!--          <input matInput type="date" formControlName="date_received" placeholder="Date" required>-->
    <!--        </mat-form-field>-->
    <mat-form-field>
      <input matInput type="date" formControlName="submission_date" placeholder="Submission Date" required>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput matTextareaAutosize formControlName="description" placeholder="Submission Description"
                required></textarea>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="site_epa_id" placeholder="Site EPA ID" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="site_name" placeholder="Site Name" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="data_provider_name" placeholder="Data Provider Name">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="email" formControlName="data_provider_email" placeholder="Data Provider Email">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="data_provider_company" placeholder="Data Provider Company">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="tel" formControlName="data_provider_phone" placeholder="Data Provider Phone">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="epa_provider_name" placeholder="EPA Project Manager Name" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="email" formControlName="epa_provider_email" placeholder="EPA Project Manager Email" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="tel" formControlName="epa_provider_phone" placeholder="EPA Project Manager Phone" required>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput matTextareaAutosize formControlName="notes" placeholder="Notes"></textarea>
    </mat-form-field>
<!--    <app-cateogry-input url="aum/chapters" placeholder="Chapters" (selectionChanged)="setValue($event, 'chapters')" [selection]="data.dataIntakeEntry.chapters"></app-cateogry-input>-->
<!--    <app-cateogry-input url="aum/settlements" placeholder="Settlements" (selectionChanged)="setValue($event, 'settlements')" [selection]="data.dataIntakeEntry.settlements"></app-cateogry-input>-->
<!--    <app-cateogry-input url="aum/primary_authors" placeholder="Primary Author" (selectionChanged)="setValue($event, 'primary_author')" [selection]="data.dataIntakeEntry.primary_author"></app-cateogry-input>-->
<!--    <app-cateogry-input url="aum/collections" placeholder="Collections" (selectionChanged)="setValue($event, 'collections')" [selection]="data.dataIntakeEntry.collections"></app-cateogry-input>-->
<!--    <app-tag-input [form]="dataIntakeForm" [tags]="data.dataIntakeEntry.tags"></app-tag-input>-->
    <div style="display: flex; flex-direction: row; justify-content: flex-end;">
      <button mat-raised-button color="accent" (click)="dialogRef.close(false)" style="margin-right: 15px"
              [disabled]="loading">
        Cancel
      </button>
      <button mat-raised-button [disabled]="!dataIntakeForm.valid || loading" color="primary" (click)="warn()">
        <span *ngIf="!loading">Save</span>
        <mat-progress-spinner style="margin: auto;" [diameter]="25" [mode]="'indeterminate'"
                              *ngIf="loading"></mat-progress-spinner>
      </button>

    </div>
  </form>


</div>
