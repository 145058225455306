<div *ngIf="aum_intake | async as intake">

  <div style="display: flex; flex: 1;">

    <mat-list style="    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around">

      <mat-list-item style="display: flex; justify-content: space-between;">
        <div class="title-column">Submission Date</div>
        <div>{{intake.submission_date}}</div>

      </mat-list-item>

      <!--      <mat-divider></mat-divider>-->
      <!--      <mat-list-item>-->
      <!--        <div class="title-column">Date Received</div>-->
      <!--        <div>{{intake.date_received | date: 'shortDate'}}</div>-->
      <!--      </mat-list-item>-->
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Submission Description</div>
        <div>{{intake.description}}</div>

      </mat-list-item>
      <!-- remove b/c files are now in S3 -->
<!--      <mat-divider></mat-divider>-->
<!--      <mat-list-item>-->
<!--        <div class="title-column">Location</div>-->
<!--        <div>{{intake.display_location}}</div>-->
<!--      </mat-list-item>-->
      <mat-divider></mat-divider>
      <!--      <mat-list-item>-->
      <!--        <div class="title-column">Intake Staff</div>-->
      <!--        <div>{{intake.intake_staff}}</div>-->
      <!--      </mat-list-item>-->
      <!--      <mat-divider></mat-divider>-->
      <mat-list-item>
        <div class="title-column">Site EPA ID</div>
        <div>{{intake.site_epa_id}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Site Name</div>
        <div>{{intake.site_name}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Data Provider Name</div>
        <div>{{intake.data_provider_name}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Data Provider Email</div>
        <div>{{intake.data_provider_email}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Data Provider Company</div>
        <div>{{intake.data_provider_company}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Data Provider Phone</div>
        <div>{{intake.data_provider_phone}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">EPA Provider Name</div>
        <div>{{intake.epa_provider_name}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">EPA Provider Email</div>
        <div>{{intake.epa_provider_email}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">EPA Provider Phone</div>
        <div>{{intake.epa_provider_phone}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Notes</div>
        <div>{{intake.notes}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">File Name</div>
        <div>{{intake.file_name}}</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">File Size</div>
        <div>{{intake.file_size / 1000000 | number: '.0-2'}} MB</div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <div class="title-column">Submission Status</div>
        <app-processing-status [intake]="intake" [progress]="intakeDialog.progress[intake.id]"
                               (complete)="processingComplete()"></app-processing-status>
        <button *ngIf="intake.processing_status === 'error'" mat-raised-button color="warn"
                (click)="openReport(intake)" style="margin-right: 15px;">View Error Report
        </button>
        <button style="margin-left: 15px;" mat-raised-button color="primary"
                (click)="openIntakeDialog($event, intake)">Edit Intake
        </button>

      </mat-list-item>
      <!--      <mat-divider></mat-divider>-->
      <!--      <mat-list-item>-->
      <!--        <div class="title-column">Intake Staff</div>-->
      <!--        <div>{{intake.intake_staff}}</div>-->
      <!--      </mat-list-item>-->
      <!--      <mat-divider></mat-divider>-->
      <!--      <mat-list-item>-->
      <!--        <div class="title-column">Keywords</div>-->
      <!--        <div>{{intake.tags.join(', ')}}</div>-->
      <!--      </mat-list-item>-->
      <!--      <mat-divider></mat-divider>-->
      <!--      <mat-list-item>-->
      <!--        <div class="title-column">Other Notes</div>-->
      <!--        <div>{{intake.other_notes}}</div>-->
      <!--      </mat-list-item>-->
    </mat-list>
    <app-map-view *ngIf="services | async as s" [map_services]="s" [base_map_id]="web_map_id"
                  (map_service)="map_service_ready($event)"></app-map-view>
  </div>
  <mat-divider></mat-divider>
  <mat-list>
    <mat-list-item style="height: unset; min-height: 48px;">
      <!--<div class="breadcrumbs-column">Indexed Files</div>-->
      <!--      <div style="display: flex; flex-direction: row; align-items: center; flex: 1;">-->
      <!--        <mat-expansion-panel style="flex:1;">-->
      <!--          <mat-expansion-panel-header>-->
      <!--            <mat-panel-breadcrumbs class="breadcrumbs-column">Original Data</mat-panel-breadcrumbs>-->
      <!--            <mat-panel-description>Total: {{ aumFilesService.count }}</mat-panel-description>-->
      <!--          </mat-expansion-panel-header>-->
      <!--          <mat-table #table [dataSource]="aumFilesService.datasource" class="table">-->
      <!--            <ng-container matColumnDef="file_name">-->
      <!--              <mat-header-cell *matHeaderCellDef class="header-cell">File Name</mat-header-cell>-->
      <!--              <mat-cell *matCellDef="let row" class="cell">{{row.display_file_name}}</mat-cell>-->
      <!--            </ng-container>-->
      <!--            <ng-container matColumnDef="item_type">-->
      <!--              <mat-header-cell *matHeaderCellDef class="header-cell">Item Type</mat-header-cell>-->
      <!--              <mat-cell *matCellDef="let row" class="cell">{{row.item_type}}</mat-cell>-->
      <!--            </ng-container>-->
      <!--            <mat-header-row *matHeaderRowDef="['file_name', 'item_type']" class="header-row"></mat-header-row>-->
      <!--            <mat-row *matRowDef="let row; columns: ['file_name', 'item_type']"></mat-row>-->
      <!--          </mat-table>-->
      <!--          <mat-paginator [length]="aumFilesService.count" [pageSize]="25"-->
      <!--                         [pageSizeOptions]="[10, 25, 50, 100]"-->
      <!--                         (page)="aumFilesService.getPage($event)"-->
      <!--                         [pageIndex]="aumFilesService.filter.page-1"></mat-paginator>-->
      <!--        </mat-expansion-panel>-->
      <!--      </div>-->
      <!--      need to add token to url for downloand-->
      <app-files-list style="display: flex; flex-direction: row; align-items: center; flex: 1;"
                      [title]="'Original Data'" [intake_ready]="processing_complete"
                      [display_fields]="['file_name', 'item_type', 'download_button', 'tagging_button']"
                      [search_filter]="{'data_intake': intake.id}"></app-files-list>
    </mat-list-item>
    <mat-list-item style="height: unset; min-height: 48px;">
      <app-feature-class-list style="display: flex; flex-direction: row; align-items: center; flex: 1;"
                              [dataDumpID]="intake.id" [intakeReady]="processing_complete"
                              (published_items)="publishMxdsReady($event)">

      </app-feature-class-list>
    </mat-list-item>
<!--    <mat-list-item style="height: unset; min-height: 48px;">-->
<!--      <app-files-list style="display: flex; flex-direction: row; align-items: center; flex: 1;"-->
<!--                      [title]="'Geospatial Layers'" [display_fields]="['file_name', 'item_type', 'status']"-->
<!--                      [search_filter]="{'item_type_in': 'Shapefile,Geodatabase', 'data_intake': intake.id}"-->
<!--                      (published_items)="publishMxdsReady($event)" [intake_ready]="processing_complete.asObservable()">-->

<!--      </app-files-list>-->
<!--    </mat-list-item>-->
  </mat-list>
</div>
