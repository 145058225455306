import { Injectable } from '@angular/core';
import {BaseService, SearchObject, Response} from '../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';

export interface MapService {
  id: number;
  root_url: string;
  mxd: string;
  mxd_server_path: string;
  server: string;
  mxd_found: boolean;
  name: string;
  publish_date: string;
  layer_count: number;
  has_featureclass: boolean;
  has_agol: boolean;
  host: string;
  is_region_9: boolean;
  is_er_cloud: boolean;
  is_ncc: boolean;
}

export interface MapServiceSearchObject extends SearchObject {
  root_url?: string;
  mxd?: string;
  mxd_server_path?: string;
  publishing_machine?: string;
  name?: string;
  layer_count?: number;
  has_featureclass?: boolean;
  has_agol?: boolean;
  location?: string;
}

@Injectable()
export class MapservicesService extends BaseService {

  constructor(http: HttpClient, loading: LoadingService) {
    super('mapservices', http, loading, ['databases', 'folders']);
    this.filter = {page: 1};
  }
}
