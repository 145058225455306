import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LoadingService} from '../shared/services/loading.service';
import {MapservicesService} from '../services/mapservices.service';
import {TitleService} from '../services/title.service';
import {ActivatedRoute, ActivatedRouteSnapshot, ParamMap, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {debounceTime, map, skip, startWith, switchMap, tap} from 'rxjs/operators';


@Component({
  selector: 'app-mapservices',
  templateUrl: './mapservices.component.html',
  styleUrls: ['./mapservices.component.css']
})
export class MapservicesComponent implements OnInit, OnDestroy {

  displayedColumns = [
    'name', 'hosting_env', 'layer_count', 'has_featureclass', 'has_agol', 'publish_date'
  ];
  locationChoices = [
    {'display': 'Region 9', 'value': 'region_9'},
    {'display': 'ER Cloud', 'value': 'er_cloud'},
    {'display': 'NCC', 'value': 'ncc'}
  ];
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);

  constructor(public mapServiceService: MapservicesService, public loadingService: LoadingService,
              public route: ActivatedRoute, public router: Router, public titleService: TitleService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Map Services');
    /*if (!this.route.snapshot.queryParams.keepFilter || this.mapServiceService.count === undefined) {
      this.mapServiceService.filter = {page: 1};
      this.mapServiceService.getItems().subscribe();
    }*/
    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        this.mapServiceService.applyQueryParams(params);
    }),
    switchMap(() => {
      return this.mapServiceService.getItems();
    })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.mapServiceService.dataChange.pipe(tap(() => {
      this.mapServiceService.updateQueryParams(this.mapServiceService.filter, this.router, this.route);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.mapServiceService.filter.search = search;
    return this.mapServiceService.runSearch();
  }

}
