import {Injectable} from '@angular/core';
import {BaseService, SearchObject} from './base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';
import {map, share} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {FeatureclassesService} from './featureclasses.service';
import {AgolitedataService} from './agolitedata.service';
import {AgolItemsService} from './agolitems.service';
import {MapservicesService} from './mapservices.service';

export interface GlobalSearchObject extends SearchObject {
}

@Injectable()
export class GlobalsearchService extends BaseService {
  filter: GlobalSearchObject;
  content_types: any = {};
  constructor(http: HttpClient, loading: LoadingService,
              private featureClassService: FeatureclassesService,
              private agolItemsService: AgolItemsService,
              private mapServicesService: MapservicesService) {
    super('global', http, loading);
    this.filter = {page: 1};
    // this.items = this.getList(this.filter).
  }

  loadContentTypes() {
    let options = [this.featureClassService.options(), this.agolItemsService.options(), this.mapServicesService.options()];
    return forkJoin(options).pipe(
      map(options => {
        this.content_types[options[0].content_type] = "root.fc.details";
        this.content_types[options[1].content_type] = "root.gpitems.details";
        this.content_types[options[2].content_type] = "root.ms.details";
      })).subscribe();
  }
}
