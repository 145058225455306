import {Injectable} from '@angular/core';
import {BaseService, SearchObject, Response} from '../shared/services/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';
import {Observable, BehaviorSubject, Subject, interval} from 'rxjs';
import {delay, flatMap, map, share, switchMap, take, tap, filter} from 'rxjs/operators';

export interface FeatureClass {
  uuid: string;
  short_name: string;
  title: string;
  db_name: string;
  description: string;
  has_agol: boolean;
  has_mapservice: boolean;
  edg_status: boolean;
  waf_status: boolean;
  has_md_uuid: boolean;
  has_abstract: boolean;
  category: string;
  time_checked: string;
  name: string;
  md_uuid: string;
  is_region_9: boolean;
  is_er_cloud: boolean;
  is_ncc: boolean;
  last_update_metadata: string;
  next_update: string;
  next_update_status: string;
  datagov_link: string;
  datagov_status: boolean;
  access_level: string;
  metadata_valid: string;
  waf: string;
  location: string;
  edg_link: string;
}

export interface FeatureClassSearchObject extends SearchObject {
  has_agol?: boolean;
  has_mapservice?: boolean;
  edg_status?: boolean;
  waf_status?: boolean;
  has_md_uuid?: boolean;
  has_abstract?: boolean;
  db_name?: string;
  category?: string;
  location?: string;
  next_update_status?: string;
  datagov_status?: boolean;
}

@Injectable()
export class FeatureclassesService extends BaseService {
  public databases: Subject<any[]> = new BehaviorSubject<any[]>([]);
  public categories: Subject<any[]> = new BehaviorSubject<any[]>([]);
  public locations: Subject<any[]> = new BehaviorSubject<any[]>([]);
  public filter: FeatureClassSearchObject;
  public all_feature_classes: Observable<any[]>;

  constructor(http: HttpClient, loading: LoadingService) {
    super('gdbitems', http, loading);
    this.filter = {page: 1};
    this.http.get<any[]>(`/${this.base_url}/databases`, {params: new HttpParams().set('page_size', '1000')})
      .subscribe(response => this.databases.next(response));
    this.http.get<any[]>(`/${this.base_url}/categories`, {params: new HttpParams().set('page_size', '1000')})
      .subscribe(response => this.categories.next(response));
    this.http.get<any[]>(`/${this.base_url}/locations`, {params: new HttpParams().set('page_size', '1000')})
      .subscribe(response => this.locations.next(response));
    this.all_feature_classes = this.http.get<Response>(`/${this.base_url}`, {params: new HttpParams().set('page_size', '10000')})
      .pipe(
        map(response => response.results),
        share()
      );
  }

  updateFilterChoices() {
    this.http.get<any[]>(`/${this.base_url}/categories`, {
      params: Object.entries(this.filter).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
    }).subscribe(response => this.categories.next(response));
    this.http.get<any[]>(`/${this.base_url}/databases`, {
      params: Object.entries(this.filter).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
    }).subscribe(response => this.databases.next(response));
    this.http.get<any[]>(`/${this.base_url}/locations`, {
      params: Object.entries(this.filter).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
    }).subscribe(response => this.locations.next(response));
  }

  rerunMetadataValidation(id) {
    const url = `/${this.base_url}/${id}/validate_metadata`;
    const results = new Subject();
    this.http.post(url, {}).pipe(
      tap(response => results.next(response['status'])),
      delay(1000),
      switchMap(() => interval(2000).pipe(
        flatMap(() => this.http.get(url)),
        map(response => {
          results.next(response['status']);
          return response['status'];
        }),
        filter(status => (status === 'complete' || status === 'error')),
        tap(() => results.complete()),
        take(1)))
    ).subscribe();
    return results;
  }
}
