/* tslint:disable:variable-name */
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../shared/services/loading.service';
import {BaseService} from '../../shared/services/base.service';
import {MatDialog} from '@angular/material/dialog';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject, timer} from 'rxjs';
import {filter, map, share, switchMap, take, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {UserConfigService} from 'auth';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-feature-class-list',
  templateUrl: './feature-class-list.component.html',
  styleUrls: ['./feature-class-list.component.css']
})
export class FeatureClassListComponent implements OnInit, OnDestroy {
  @Input() dataDumpID: string;
  @Input() intakeReady: Observable<void>;
  @Output() published_items = new EventEmitter<any>();

  featureClassService: BaseService;
  agolItemService: BaseService;
  serviceLayerService: BaseService;
  indexedFiles: BaseService;
  displayedColumns = ['short_name', 'db_name', 'metadata_valid', 'item_url', 'status'];
  results: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public statuses: { [key: number]: ReplaySubject<string> } = {};

  constructor(http: HttpClient, loadingService: LoadingService, public dialog: MatDialog,
              config: UserConfigService) {
    this.featureClassService = new BaseService('gdbitems', http, loadingService);
    this.featureClassService.filter = {page: 1};

    this.agolItemService = new BaseService('agolitems', http, loadingService);
    this.agolItemService.filter = {page: 1};

    this.serviceLayerService = new BaseService(`servicelayers`, http, loadingService);
    this.serviceLayerService.filter = {page: 1};

    this.indexedFiles = new BaseService('aum/indexedfiles', http, loadingService);

  }

  ngOnInit(): void {
    this.getRecords().subscribe();
    this.intakeReady.pipe(switchMap(() => this.getRecords())).subscribe();
  }

  ngOnDestroy(): void {
    Object.keys(this.statuses).forEach(k => this.statuses[k].unsubscribe());
  }

  showMetadataError(e, feature_class_id) {
    e.stopPropagation();
    this.dialog.open(GenericDialogComponent, {
      width: '1000px',
      data: {
        title: 'Metadata Validation Error(s)',
        show_metadata: true,
        content: feature_class_id
      }
    });
  }

  getRecords(fc_filter: { [key: string]: any } = {indexed_file__data_intake: this.dataDumpID},
             agol_filter: { [key: string]: any } = {indexed_file__data_intake_id: this.dataDumpID},
             layers_filter: { [key: string]: any } = {agolItem__indexed_file__data_intake_id: this.dataDumpID},
             updateTable = true) {
    return forkJoin({
      fc: this.featureClassService.getList(fc_filter),
      agol: this.agolItemService.getList(agol_filter),
      layers: this.serviceLayerService.getList(layers_filter)
    }).pipe(map(results => {
      const final_results = this.buildResult(results.fc, results.agol, results.layers);
      if (updateTable) {
        final_results.forEach(f => this.getStatus(f.indexed_file));
        this.results.next(final_results);
      }

      // this.published_items.emit(final_results.filter(f => f.map_service_url));
      return final_results;
    }));
  }

  getStatus(id: number): void {
    if (!this.statuses.hasOwnProperty(id)) {
      this.statuses[id] = new ReplaySubject();
      timer(0, 10 * 1000).pipe(
        switchMap(() => this.indexedFiles.get(id)),
        tap(file => this.statuses[file.id].next(file.status)),
        filter(file => ['completed', 'error', 'failed'].includes(file.status)),
        take(1),
        tap(file => {
          if (file.status === 'completed') {
            this.emitReady(file);
          }
        }),
      ).subscribe();
    }
  }

  buildResult(fc, agol, layers) {
    return fc.results.map(f => {
      // get corresponding service layer
      const service_layer = layers.results.find(l => l.gdb_status === f.uuid);
      if (service_layer) {
        const agol_item = agol.results.find(a => a.serviceLayer.includes(service_layer.id));
        if (agol_item !== undefined) {
          f.map_service_url = `${agol_item.url}/${service_layer.layer_index}`;
          f.agol_item_url = `${environment.portal_base_url}/home/item.html?id=${agol_item.item_id}`;
        }
        f.display_file_name = f.name;
        f.status = 'completed';
      } else {
        f.display_file_name = 'unknown';
      }

      return f;
    });

  }

  emitReady(file: any) {
    const ready_results = this.results.getValue().filter(r => r.indexed_file === file.id && r.map_service_url);
    if (ready_results.length > 0) {
      this.published_items.emit(ready_results);
    }
    const not_ready = this.results.getValue().filter(r => r.indexed_file === file.id && !r.map_service_url);
    if (not_ready.length > 0) {
      this.refreshResult(file).pipe(
        tap(results => this.published_items.emit(results))
      ).subscribe();
    }
  }

  refreshResult(file: any): Observable<any> {
    return this.getRecords(
      {indexed_file: file.id},
      {indexed_file: file.id},
      {agolItem__indexed_file: file.id},
      false
    ).pipe(
      map(updated_results => {
        const updatedFinalResults = this.results.getValue()
          .map(result => updated_results.find(f => f.uuid === result.uuid) || result);
        this.results.next(updatedFinalResults);
        return updated_results;
      })
    );
  }
}
