import { Injectable } from '@angular/core';
import {BaseService, SearchObject} from './base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';


export interface AgolitemBackup {
  id: number;
  dataitemcount: number;
  item_backup_json: string;
  itemdata_backup_json: string;
  backupdate: string;
  item: string;
}

export interface AgolitemBackupSearchObject extends SearchObject {
  item?: string;
}

@Injectable()
export class AgolitembackupsService extends BaseService {
  public filter: AgolitemBackupSearchObject;

  constructor(http: HttpClient, loading: LoadingService) {
    super('agolitems/backups', http, loading);
    this.filter = {page: 1};
  }

}
