<div class="details-container">
  <div style="flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
    margin-right: 30px;">
    <button mat-raised-button type="button" color="primary" routerLink="/mapservices" [queryParams]="{'keepFilter':true}">
      <mat-icon style="margin-left: -10px;">keyboard_arrow_left</mat-icon>
      Back
    </button>
  </div>
  <mat-list>
    <mat-list-item>
      <div class="title-column">Name</div>
      <div>{{ mapService.name }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Url</div>
      <div>{{ mapService.root_url }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Published</div>
      <div>{{ mapService.publish_date | date:'MMM d, y, h:mm a' }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Environment</div>
      <div style="display: flex; align-items: center;">
              <mat-icon *ngIf="mapService.location==='region_9'" style="color:#202020;" matTooltip="Region 9">computer</mat-icon>
              <mat-icon *ngIf="mapService.location==='er_cloud'" style="color:#202020;" matTooltip="ER Cloud">cloud_queue</mat-icon>
              <mat-icon *ngIf="mapService.location==='ncc'" style="color:#202020;" matTooltip="NCC">public</mat-icon>
        <div style="margin-left: 15px;">{{ mapService.map_document_server_path }}</div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Publishing Machine</div>
      <div style="padding-left: 39px;">{{ mapService.publishing_machine }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Publishing Document Found &nbsp;</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <div>
          <display-check [value]="mapService.map_document_found"></display-check>
        </div>
        <div style="margin-left: 15px;" [innerHTML]="mapService.map_document"></div>
      </div>
    </mat-list-item>

    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">GeoPlatform</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">
        <display-check [value]="mapService.has_agol"></display-check>
        <mat-expansion-panel style="flex:1; margin-left: 15px;">
          <mat-expansion-panel-header>
            <mat-panel-title style="font-size: small"><b>Items</b></mat-panel-title>
            <mat-panel-description>Total: {{ item_inline_count }}</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-table #table [dataSource]="item_inline_datasource" class="table">
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef class="header-cell">Title</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a routerLink="/geoplatformitems/{{row.item_id}}">{{ row.title }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef class="header-cell">Type</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.type }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="owner">
              <mat-header-cell *matHeaderCellDef class="header-cell">Owner</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.owner }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['title', 'type', 'owner']" class="header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['title', 'type', 'owner']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>

    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Service Layers</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">
        <mat-expansion-panel style="flex:1; margin-left: 38px;">
          <mat-expansion-panel-header>
            <mat-panel-title style="font-size: small"><b>Feature Classes</b></mat-panel-title>
            <mat-panel-description>Total: {{ service_inline_count }} (Click for Enterprise Library Details)
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-table #table [dataSource]="service_inline_datasource" class="table">
            <ng-container matColumnDef="layer_index">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layer Index</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.layer_index }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="service_layer_name">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layer Name</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.service_layer_name }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="feature_class">
              <mat-header-cell *matHeaderCellDef class="header-cell">Feature Class</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a *ngIf="row.gdb_status !== null" routerLink="/featureclasses/{{row.gdb_status}}">
                  {{ row.feature_class }}
                </a>
                <span *ngIf="row.gdb_status === null">{{ row.feature_class }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gdb_status">
              <mat-header-cell *matHeaderCellDef class="header-cell checkmark">Enterprise Library</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell checkmark">
                <display-check [value]="row.gdb_status"></display-check>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="database_name">
              <mat-header-cell *matHeaderCellDef class="header-cell">
                Database
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.database_name }}</mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="['layer_index', 'service_layer_name', 'feature_class','gdb_status', 'database_name']"
              class="header-row"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: ['layer_index', 'service_layer_name','feature_class', 'gdb_status', 'database_name']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item *ngIf="mapService.id" style="height: unset; min-height: 48px;">
      <div class="title-column">Change Log</div>
      <change-log-inline style="display: flex; flex-direction: row; align-items: center; flex: 1;  margin-left: 23px;"
                         [id]="mapService.id" [content_type]="mapServices.content_type">
      </change-log-inline>
    </mat-list-item>
  </mat-list>

</div>
