<mat-sidenav-container class="container">
  <mat-sidenav #filterNav mode="side" opened="false" position="end" class="filter-sidenav">
    <mat-list>
      <mat-list-item>
        <lib-filter-input [(filtered)]="mapServiceService.filter.location"
                          (filterCleared)="mapServiceService.clearFilter('location')"
                          label="Environment" type="choices" [choices]="locationChoices"
                          field_name="display" value_field="value"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="mapServiceService.filter.has_featureclass"
                          (filterCleared)="mapServiceService.clearFilter('has_featureclass')"
                          label="Enterprise Library" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="mapServiceService.filter.has_agol"
                          (filterCleared)="mapServiceService.clearFilter('has_agol')"
                          label="GeoPlatform" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item style="display: flex; justify-content: space-around;">
        <lib-filter-input *ngIf="mapServiceService.filter_options.hasOwnProperty('databases')"
                          label="Database" type="choices" [choices]="mapServiceService.filter_options.databases | async"
                          [(filtered)]="mapServiceService.filter.service_layers__database_name"
                          (filterCleared)="mapServiceService.clearFilter('service_layers__database_name')"
                          field_name="display" value_field="value">
        </lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input *ngIf="mapServiceService.filter_options.hasOwnProperty('folders')"
                          label="Folder" type="choices" [choices]="mapServiceService.filter_options.folders | async"
                          [(filtered)]="mapServiceService.filter.folder__id"
                          (filterCleared)="mapServiceService.clearFilter('folder__id')"
                          field_name="display" value_field="value">
        </lib-filter-input>
      </mat-list-item>
      <div style="text-align: center;">
        <button type="button" (click)="mapServiceService.runSearch()" mat-raised-button color="primary">
          Apply
        </button>&nbsp;
        <button type="button" (click)="mapServiceService.clearAllFilters()" mat-raised-button color="accent">
          Clear All
        </button>
      </div>
    </mat-list>
  </mat-sidenav>
  <div class="sidenav-content">
    <div class="list-header">
      <mat-form-field style="width:250px;">
        <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
        <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
                (click)="searchInput.setValue(null);"
                *ngIf="mapServiceService.filter.search">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div>
        <button type="button" mat-raised-button (click)="filterNav.toggle()" color="primary">
          {{ filterNav.opened ? 'Hide Filter' : 'Show Filter' }}
        </button>
      </div>
    </div>
    <div class="list-container">
      <mat-table #table [dataSource]="mapServiceService.datasource" class="table" matSort
                 (matSortChange)="mapServiceService.sortTable($event)">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell shortname"
                           matTooltip="Name of the map service in the format FolderName/ServiceName."
                           [matTooltipPosition]="'above'">Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell shortname"
                    matTooltip="{{ row.name }}"> {{ row.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="layer_count">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Total number of layers in the map service." [matTooltipPosition]="'above'">
            Layers
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark"> {{ row.layer_count }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="hosting_env">
          <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                           matTooltip="Indicates the ArcGIS Server environment in which the map service was published (Region 9, ER Cloud, NCC). Hover over the icon to see details."
                           [matTooltipPosition]="'above'">
            Environment
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.location==='region_9'" style="color:#202020;" matTooltip="Region 9">computer
            </mat-icon>
            <mat-icon *ngIf="row.location==='er_cloud'" style="color:#202020;" matTooltip="ER Cloud">cloud_queue
            </mat-icon>
            <mat-icon *ngIf="row.location==='ncc'" style="color:#202020;" matTooltip="NCC">public</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_featureclass">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the map service is using feature classes from an enterprise geodatabase. A green check will appear if a minimum of 1 layer uses local content.
                             Open the items details to view the status of all layers individually."
                           [matTooltipPosition]="'above'">Enterprise Library
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <display-check [value]="row.has_featureclass"></display-check>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_agol">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the map service is used in any GeoPlatform items."
                           [matTooltipPosition]="'above'">GeoPlatform
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <display-check [value]="row.has_agol"></display-check>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="publish_date">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Indicates the date the map service was last modified. For R9 and ER Cloud services, this indicates
                              the most recent date a service was published or republished. For NCC services, this indicates the most recent
                               date the local MXD was modified but does not reflect publishing updates. Please note that all R9 dates were reset
                               when the ArcGIS Server instance was migrated to a new server in June 2017."
                           [matTooltipPosition]="'above'">Last Modified
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell">
            {{ row.publish_date | date:'MMM d, y, h:mm a' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="open_in_new">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell" (click)="$event.stopPropagation()"
                    matTooltip="Open in new tab.">
            <a routerLink="/mapservices/{{row.id}}" target="_blank">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer;"
                 routerLink="/mapservices/{{row.id}}"></mat-row>
      </mat-table>
      <mat-paginator [length]="mapServiceService.count" [pageSize]="25"
                     [pageSizeOptions]="[10, 25, 50, 100]"
                     (page)="mapServiceService.getPage($event)"
                     [pageIndex]="mapServiceService.filter.page-1"></mat-paginator>
    </div>
  </div>
</mat-sidenav-container>
