<!--<mat-sidenav-container class="container">-->
<!--  <div class="sidenav-content">-->
<div class="list-header">
  <mat-form-field style="width:250px; padding-top: 15px;">
    <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
    <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
      (click)="searchInput.setValue(null);"
      *ngIf="aumService.filter.search">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div>
    <button mat-raised-button color="primary"
            (click)="openDialog($event, {intake_storage})">
      Add Intake
    </button>
  </div>
</div>
<div class="list-container">
  <mat-table #table [dataSource]="aumService.datasource" class="table" matSort matSortActive="item_name"
             matSortDirection="desc"
             (matSortChange)="aumService.sortTable($event)">
    <!--        <ng-container matColumnDef="date_received">-->
    <!--          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">-->
    <!--            Date Received-->
    <!--          </mat-header-cell>-->
    <!--          <mat-cell *matCellDef="let row" class="cell">{{row.date_received | date: 'shortDate'}}</mat-cell>-->
    <!--        </ng-container>-->

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="header-cell">Description</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="data_provider_name">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Data Provider</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.data_provider_name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="site_name">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Site Name</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.site_name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="submission_date">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Submission Date</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.submission_date}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="processing_status">
      <mat-header-cell *matHeaderCellDef class="header-cell">Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <app-processing-status [intake]="row" [progress]="intakeDialog.progress[row.id]"></app-processing-status>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef class="header-cell">Edit</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <button mat-icon-button *ngIf="row.processing_status !== 'pending' && row.processing_status !== 'processing'"
                (click)="openDialog($event, row)">
          <mat-icon>mode_edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer;"
             routerLink="./{{row.id}}">

    </mat-row>
  </mat-table>
  <mat-paginator [length]="aumService.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="aumService.getPage($event)"
                 [pageIndex]="aumService.filter.page-1"></mat-paginator>
</div>
<!--  </div>-->
<!--</mat-sidenav-container>-->
