import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayValue'
})
export class DisplayValuePipe implements PipeTransform {

  transform(value: any, display: any, field: string, pk_field: string): any {
    if (pk_field === undefined) pk_field = 'id';
    if (display) {
      let found = display.filter(item => item[pk_field] === value);
      if (found.length > 0) {
        return found[0][field]
      } else return value;
    } else return value;
  }

}
