import {Component, OnInit} from '@angular/core';
import {FeatureclassesService, FeatureClass} from '../../services/featureclasses.service';
import {TitleService} from '../../services/title.service';
import {ServicelayersService} from '../../services/servicelayers.service';
import {AgolItemsService} from '../../services/agolitems.service';
import {ChangelogService} from '../../services/changelog.service';
import { MatDialog } from '@angular/material/dialog';
import {LoadingService} from '../../shared/services/loading.service';
import {switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {BaseService} from '../../shared/services/base.service';
import {ReplaySubject, Subject} from 'rxjs';

@Component({
  selector: 'featureclass-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class FeatureClassDetailsComponent implements OnInit {
  featureClass: ReplaySubject<FeatureClass> = new ReplaySubject<FeatureClass>();
  back_state: string;
  showSave = false;
  validation_status: any;
  resultsService: BaseService;

  constructor(public featureClasses: FeatureclassesService, public route: ActivatedRoute,
              public titleService: TitleService, public serviceLayersService: ServicelayersService,
              public agolItemsService: AgolItemsService, public changeLogService: ChangelogService,
              public dialog: MatDialog, public loadingService: LoadingService) {
  }

  ngOnInit() {
    // let previous_state = this.state.params.previousState;
    // this.back_state = previous_state ? previous_state : 'root.fc';
    this.back_state = 'root.fc';
    this.featureClasses.get(this.route.snapshot.params.id).subscribe(response => {
      this.featureClass.next(response);
      this.titleService.setTitle(`Feature Class: ${response.title}`);
      this.serviceLayersService.filter.gdb_status = response.uuid;
      this.serviceLayersService.getItems().subscribe();
      this.agolItemsService.filter.gslib_fc = response.uuid;
      this.agolItemsService.getItems().subscribe();
      this.featureClasses.content_type.subscribe(content_type => {

      });
    });
  }

  saveAccess(fc: FeatureClass) {
    this.featureClasses.patch(this.route.snapshot.params.id, {'access_level': fc.access_level}).pipe(
      tap(() => this.showSave = false)
    ).subscribe();
  }

  rerunValidation(event, id) {
    event.stopPropagation();
    this.featureClasses.rerunMetadataValidation(id).pipe(
      tap(status => this.validation_status = status),
      switchMap(() => this.featureClasses.get(this.route.snapshot.params.id)),
      tap(fc => this.featureClass.next(fc))
    ).subscribe();
  }


}
