<mat-sidenav-container class="container">
  <mat-sidenav #filterNav mode="side" opened="false" position="end" class="filter-sidenav">
    <mat-list>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.location"
                      (filterCleared)="clearFilter('location')"
                      (filteredChange)="featureClassService.updateFilterChoices()"
                      label="Environment" type="choices" [choices]="featureClassService.locations | async"
                      field_name="display" value_field="value"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.db_name"
                      (filterCleared)="clearFilter('db_name')"
                      (filteredChange)="featureClassService.updateFilterChoices()"
                      label="Database" type="choices" [choices]="featureClassService.databases | async"
                      field_name="db_name"></lib-filter-input>
      </mat-list-item>
      <mat-list-item matTooltip="Category indicates the feature dataset in which a feature class resides."
                     [matTooltipPosition]="'before'">
        <lib-filter-input [(filtered)]="featureClassService.filter.category"
                      (filterCleared)="clearFilter('category')"
                      (filteredChange)="featureClassService.updateFilterChoices()"
                      label="Category" type="choices" [choices]="featureClassService.categories | async"
                      field_name="category"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.has_abstract"
                      (filterCleared)="clearFilter('has_abstract')"
                      label="Abstract" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.has_md_uuid"
                      (filterCleared)="clearFilter('has_md_uuid')"
                      label="Metadata UUID" type="boolean"></lib-filter-input>
      </mat-list-item>

      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.waf_status"
                      (filterCleared)="clearFilter('waf_status')"
                      label="WAF XML" type="boolean"></lib-filter-input>
      </mat-list-item>

      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.edg_status"
                      (filterCleared)="clearFilter('edg_status')"
                      label="EDG Record" type="boolean"></lib-filter-input>

      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.datagov_status"
                      (filterCleared)="clearFilter('datagov_status')"
                      label="Data.gov" type="boolean"></lib-filter-input>

      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.has_mapservice"
                      (filterCleared)="clearFilter('has_mapservice')"
                      label="Map Service" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.has_agol"
                      (filterCleared)="clearFilter('has_agol')"
                      label="GeoPlatform" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="featureClassService.filter.next_update_status"
                      (filterCleared)="clearFilter('next_update_status')"
                      label="Update Cycle" type="choices" [choices]="updateStatusChoices"
                      field_name="display" value_field="value"></lib-filter-input>
      </mat-list-item>
      <div style="display: flex; justify-content: space-around;">
        <button type="button" (click)="featureClassService.runSearch()" mat-raised-button
                color="primary">
          Apply
        </button>
        <button type="button" (click)="clearAllFilters()" mat-raised-button color="accent">
          Clear All
        </button>
      </div>
    </mat-list>
  </mat-sidenav>
  <div class="sidenav-content">
    <div class="list-header">
      <mat-form-field style="width:250px;">
        <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
          <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
            (click)="searchInput.setValue(null);"
            *ngIf="featureClassService.filter.search">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div>
          <button type="button" mat-raised-button (click)="filterNav.toggle()" color="primary">
            {{ filterNav.opened ? 'Hide Filter' : 'Show Filter' }}
          </button>
        </div>
      </div>
      <div class="list-container">
        <mat-table #table [dataSource]="featureClassService.datasource" class="table" matSort
                   (matSortChange)="featureClassService.sortTable($event)">
          <ng-container matColumnDef="db_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Source database. Use the Database filter to view a specific database."
                           [matTooltipPosition]="'above'">Database
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark"> {{ row.db_name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="short_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell shortname"
                           matTooltip="Name of the feature class." [matTooltipPosition]="'above'">Feature Class
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell shortname"
                    matTooltip="{{ row.short_name }}"> {{ row.short_name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_abstract">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if an abstract is included in the feature class metadata. Does not indicate quality of text."
                           [matTooltipPosition]="'above'">Abstract
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.has_abstract" style="color:green;">check</mat-icon>
            <mat-icon *ngIf="!row.has_abstract" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_md_uuid">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if a UUID is included in the feature class metadata. This UUID is used to determine if the metadata record has been synced to EDG."
                           [matTooltipPosition]="'above'">Metadata UUID
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.has_md_uuid" style="color:green;">check</mat-icon>
            <mat-icon *ngIf="!row.has_md_uuid" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="waf_status">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the metadata in the feature class has been synced to the WAF. The title of the metadata must match the XML file name to be true."
                           [matTooltipPosition]="'above'">WAF XML
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.waf_status" style="color:green;">check</mat-icon>
            <mat-icon *ngIf="!row.waf_status" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edg_status">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the metadata for the feature class has been synced to EDG using the UUID in the feature class."
                           [matTooltipPosition]="'above'">EDG Record
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <display-check [value]="row.edg_status"></display-check>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="datagov_status">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the metadata for the feature class has been synced to Data.gov"
                           [matTooltipPosition]="'above'">Data.gov
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <display-check [value]="row.datagov_status"></display-check>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_mapservice">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the feature class is used in an internal Region 9 map service. Click into the record to see the service(s)."
                           [matTooltipPosition]="'above'">Map Service
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.has_mapservice" style="color:green;">check</mat-icon>
            <mat-icon *ngIf="!row.has_mapservice" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="has_agol">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Indicates if the feature class is used in any GeoPlatform items. Click into the record to see the item(s)."
                           [matTooltipPosition]="'above'">GeoPlatform
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.has_agol" style="color:green;">check</mat-icon>
            <mat-icon *ngIf="!row.has_agol" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="time_checked">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Indicates if the most recent status refresh." [matTooltipPosition]="'above'">
            Checked
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell">
            {{ row.time_checked | date:'medium' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="access_level">
          <mat-header-cell *matHeaderCellDef class="header-cell">
            Allowable Access Level
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">
            {{ access_levels[row.access_level] }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="er_cloud">
          <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                           matTooltip="Indicates the ArcGIS Server environment in which the map service was published (Region 9, ER Cloud, NCC). Hover over the icon to see details."
                           [matTooltipPosition]="'above'">Environment
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.location === 'er_cloud'" style="color:#202020;" matTooltip="ER Cloud">cloud_queue
            </mat-icon>
            <mat-icon *ngIf="row.location === 'region_9'" style="color:#202020;" matTooltip="Region 9">computer
            </mat-icon>
            <mat-icon *ngIf="row.location === 'ncc'" style="color:#202020;" matTooltip="NCC">public</mat-icon>
            <mat-icon *ngIf="row.location === 'intake'" style="color:#202020;" matTooltip="Superfund Intake">terrain
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="next_update_status">
          <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                           matTooltip="Indicates the update cycle status for the feature class."
                           [matTooltipPosition]="'above'">Update Cycle
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.next_update_status === 'Date Missing' || row.next_update_status === null"
                      style="color:red;" matTooltip="Date Missing">report_problem
            </mat-icon>
            <mat-icon *ngIf="row.next_update_status === 'Needs Review'" style="color:orange;"
                      matTooltip="Needs Review">update
            </mat-icon>
            <mat-icon *ngIf="row.next_update_status === 'Current'" style="color:green;" matTooltip="Current">check
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="open_in_new">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell" (click)="$event.stopPropagation()"
                    matTooltip="Open in new tab.">
            <a routerLink="/featureclasses/{{row.uuid}}" target="_blank">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer;"
                 routerLink="/featureclasses/{{row.uuid}}"></mat-row>
      </mat-table>
      <mat-paginator [length]="featureClassService.count" [pageSize]="25"
                     [pageSizeOptions]="[10, 25, 50, 100]"
                     (page)="featureClassService.getPage($event)"
                     [pageIndex]="featureClassService.filter.page-1"></mat-paginator>
    </div>
  </div>
</mat-sidenav-container>
