import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {LoadingService} from '../shared/services/loading.service';
import {ExternalServersService} from '../services/externalservers.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-external-server-dialog',
  templateUrl: './external-server-dialog.component.html',
  styleUrls: ['./external-server-dialog.component.css']
})
export class ExternalServerDialogComponent implements OnInit {
  externalServerForm = new FormGroup({
    id: new FormControl(),
    organization: new FormControl(null, Validators.required),
    office: new FormControl(),
    site_url: new FormControl(null, Validators.required),
    notes: new FormControl()
  });
  loading: boolean = false;

  public errors = {};

  constructor(public dialogRef: MatDialogRef<ExternalServerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public loadingService: LoadingService,
              public snackBar: MatSnackBar,
              public externalServers: ExternalServersService) {
  }

  ngOnInit() {
    this.externalServerForm.patchValue(this.data.external_server);
    this.loadingService.getLoadingStream().subscribe(value => {
      setTimeout(() => this.loading = value)
    });
  }

  save() {
    if (this.externalServerForm.value.id) {
      this.externalServers.put(this.externalServerForm.value.id, this.externalServerForm.value)
        .pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(response => {
            Object.assign(this.data.external_server, response);
            this.dialogRef.close();
          },
          error => this.snackBar.open(JSON.parse(error.error).details, null, {duration: 3000})
        );
    } else {
      this.externalServers.post(this.externalServerForm.value)
        .pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(server => {
            this.dialogRef.close();
          },
          errors => {
            for (let key in errors.error) {
              this.externalServerForm.controls[key].setErrors({'server_error': errors.error[key].join(',')});
            }
          }
        );
    }
  }

}
