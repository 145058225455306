import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {RootComponent} from './root/root.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
// import {FlexLayoutModule} from "@angular/flex-layout";
import {HttpRequestInterceptor} from './http-request.interceptor';
import { FeatureClassesComponent } from './featureclasses/featureclasses.component';
import {FeatureclassesService} from './services/featureclasses.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FeatureClassDetailsComponent } from './featureclasses/details/details.component';
import {TitleService} from './services/title.service';
import {ServicelayersService} from './services/servicelayers.service';
import {AgolItemsService} from './services/agolitems.service';
import {MapservicesComponent} from './mapservices/mapservices.component';
import {MapservicesService} from './services/mapservices.service';
import { GeoplatformComponent } from './geoplatform/geoplatform.component';
// import {GeoplatformService} from './services/geoplatform.service';
import {MapserviceDetailsComponent} from './mapservices/details/details.component';
import {GeoPlatformDetailsComponent} from './geoplatform/details/details.component';
import {ChangelogService} from './services/changelog.service';
import { ChangeLogDialogComponent } from './change-log-dialog/change-log-dialog.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {AgolitembackupsService} from './services/agolitembackups.service';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import {AgolitedataService} from './services/agolitedata.service';
import { ChangeLogInlineComponent } from './change-log-inline/change-log-inline.component';
import {GlobalsearchService} from './services/globalsearch.service';
import { ExternalServersComponent } from './external-servers/external-servers.component';
import {ExternalServersService} from './services/externalservers.service';
import { ExternalServerDialogComponent } from './external-server-dialog/external-server-dialog.component';
import { GlobalSearchResultsComponent } from './global-search-results/global-search-results.component';
import {AppRoutingModule} from './app-routing.module';
import {AumModule} from './aum/aum.module';
import {SharedModule} from './shared/shared.module';
import {MetadataResultsComponent} from './featureclasses/metadata-results/metadata-results.component';
import {environment} from '../environments/environment';
import {AuthModule} from 'auth';
import {SharedModule as GlobalSharedModule} from 'shared';
// import { MapServicesDetailsComponent } from './mapservices/mapservices/details/details/details.component';


// import {enableProdMode} from '@angular/core';
//
// enableProdMode();


@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    FeatureClassesComponent,
    DashboardComponent,
    FeatureClassDetailsComponent,
    MapservicesComponent,
    GeoplatformComponent,
    MapserviceDetailsComponent,
    GeoPlatformDetailsComponent,
    ChangeLogDialogComponent,
    ChangelogComponent,
    ConfirmationDialogComponent,
    ConfirmationPopupComponent,
    ChangeLogInlineComponent,
    ExternalServersComponent,
    ExternalServerDialogComponent,
    GlobalSearchResultsComponent,
    MetadataResultsComponent
  ],
  imports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    // MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({cookieName: 'dmt_csrftoken', headerName: 'X-CSRFToken'}),
    BrowserAnimationsModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AumModule,
    SharedModule,
    AuthModule.forRoot(environment, [{type: 'agol', label: 'Login using EPA GeoPlatform'}]),
    GlobalSharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    // LoginService,
    // LoadingService,
    FeatureclassesService,
    TitleService,
    ServicelayersService,
    AgolItemsService,
    MapservicesService,
    // GeoplatformService,
    ChangelogService,
    AgolitembackupsService,
    AgolitedataService,
    GlobalsearchService,
    ExternalServersService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ChangeLogDialogComponent, ConfirmationDialogComponent, ConfirmationPopupComponent,
    ExternalServerDialogComponent]
})
export class AppModule {
}
