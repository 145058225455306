import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ChangelogService} from '../services/changelog.service';
import {LoadingService} from '../shared/services/loading.service';
import {GlobalSearchObject, GlobalsearchService} from '../services/globalsearch.service';
import {finalize, startWith, debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-change-log-dialog',
  templateUrl: './change-log-dialog.component.html',
  styleUrls: ['./change-log-dialog.component.css']
})
export class ChangeLogDialogComponent implements OnInit {
  changeLogForm = new FormGroup({
    id: new FormControl(),
    date: new FormControl(null, Validators.required),
    action: new FormControl('', Validators.required),
    comments: new FormControl(''),
    analyst: new FormControl(null),
    completion_date: new FormControl(null),
    object_id: new FormControl(),
    content_type: new FormControl(),
    status: new FormControl('', Validators.required),
    last_updated: new FormControl(null),
    object_placeholder: new FormControl(),
    object_display: new FormControl()
  });

  results: any[];

  constructor(public dialogRef: MatDialogRef<ChangeLogDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public loadingService: LoadingService,
              public snackBar: MatSnackBar,
              public changeLogService: ChangelogService,
              public globalSearchService: GlobalsearchService) {
  }

  ngOnInit() {
    this.changeLogForm.patchValue(this.data.changeLogEntry);
    this.changeLogForm.controls.object_display.valueChanges
      .pipe(
        startWith(this.changeLogForm.value.object_display),
        debounceTime(300)
      )
      .subscribe(search => this.search(search));
    // console.log(this.results);
  }

  save() {
    if (this.data.showItem && this.results) {
      const found = this.results.filter(result => result.id === this.changeLogForm.value.object_id).length;
      if (found === 0) {
        this.changeLogForm.patchValue({
          object_id: null, content_type: null, object_placeholder: this.changeLogForm.value.object_display
        });
      }
    }

    this.loadingService.setLoading(true);

    if (this.changeLogForm.value.id) {
      this.changeLogService.put(this.changeLogForm.value.id, this.changeLogForm.value)
        .pipe(
          finalize(() => this.loadingService.setLoading(false))
        )
        .subscribe(response => {
            Object.assign(this.data.changeLogEntry, response);
            this.dialogRef.close();
          },
          error => this.snackBar.open(JSON.parse(error.error).details, null, {duration: 3000})
        );
    } else {
      this.changeLogService.post(this.changeLogForm.value).pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(task => {
          this.dialogRef.close();
        });
    }
  }

  search(search: any) {
    let search_object: GlobalSearchObject = {search: search};
    return this.globalSearchService.getList(search_object)
      .subscribe(response => this.results = response.results);
  }

  selected(e) {
    // this.changeLogForm.value.object_id = e.option.value;
    const selected = this.results.filter(result => result.id == e.option.value)[0];
    // this.changeLogForm.value.content_type = selected.content_type;
    this.changeLogForm.patchValue({
      object_id: e.option.value, content_type: selected.content_type, object_display: selected.name
    });
  }

  clearField(e, field: string) {
    e.stopPropagation();
    const patchValue = {};
    patchValue[field] = null;
    this.changeLogForm.patchValue(patchValue);
  }

}
