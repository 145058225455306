import { Injectable } from '@angular/core';
import {BaseService, SearchObject} from './base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';


export interface ServiceLayer {
  layer_index: number;
  service_layer_name: string;
  feature_class: string;
  database_name: string;
  gdb_status: string;
  map_service_name: string;
}

export interface ServiceLayerSearchObject extends SearchObject {
  gdb_status?: string;
  map_service__id?: number;
  agolItemData__item__item_id?: string;
  agolItem__item_id?: string;
  agolItemData?: number;
}

@Injectable()
export class ServicelayersService extends BaseService {
  filter: ServiceLayerSearchObject;

  constructor(http: HttpClient, loading: LoadingService) {
    super('servicelayers', http, loading);
  }

}
