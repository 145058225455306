import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapViewComponent} from './map-view/map-view.component';
import {UserConfigService} from './services/user-config.service';
import {LoadingService} from './services/loading.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {DisplayCheckComponent} from './display-check/display-check.component';
import {DisplayValuePipe} from './pipes/display-value.pipe';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {BreadcrumbService} from './services/breadcrumb.service';
import {RouterModule} from '@angular/router';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {HttpClientModule} from '@angular/common/http';
import {GenericDialogComponent} from './generic-dialog/generic-dialog.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {ConfirmationPopupComponent} from './confirmation-popup/confirmation-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AddServiceButtonComponent} from './add-service-button/add-service-button.component';
import {AddServiceDialogComponent} from './add-service-dialog/add-service-dialog.component';
import {SaveMapButtonComponent} from './save-map-button/save-map-button.component';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthService} from 'auth';

declare global {
  interface Window {
    locationPath: string;
    dojoConfig: any;
  }
}

@NgModule({
  declarations: [
    MapViewComponent,
    DisplayCheckComponent,
    DisplayValuePipe,
    BreadcrumbComponent,
    GenericDialogComponent,
    ConfirmationDialogComponent,
    ConfirmationPopupComponent,
    AddServiceButtonComponent,
    AddServiceDialogComponent,
    SaveMapButtonComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    BrowserAnimationsModule
  ],
  exports: [
    MapViewComponent,
    DisplayCheckComponent,
    DisplayValuePipe,
    BreadcrumbComponent,
    GenericDialogComponent,
    ConfirmationDialogComponent,
    ConfirmationPopupComponent
  ],
  providers: [
    UserConfigService,
    LoadingService,
    AuthService,
    BreadcrumbService
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ConfirmationPopupComponent,
    GenericDialogComponent,
    AddServiceDialogComponent
  ]
})
export class SharedModule {
  constructor() {
    window.locationPath = location.pathname.replace(/\/[^\/]+$/, '');
    window.dojoConfig = {
      packages: [
        {
          name: 'aum',
          location: '/assets'
        }
      ]
    };
  }
}
