import {Component, Inject, OnInit} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  FormControl,
  FormGroup,
  RequiredValidator,
  ValidationErrors,
  ValidatorFn,
  Validators,
  Validator
} from '@angular/forms';
import {finalize, tap} from 'rxjs/operators';
import {HttpClient, HttpXsrfTokenExtractor} from '@angular/common/http';
import {Resumable} from 'resumablejs';
import {environment} from '../../../environments/environment';
import {AumService} from '../services/aum.service';
import {ConfirmationDialogComponent} from '../../confirmation-dialog/confirmation-dialog.component';

export interface IntakeType {
  value: string;
}


export interface Dates {
  value: number;
}


export const YearValidator: ValidatorFn = (control: FormGroup) => {
  const start = control.get('start_year').value;
  const end = control.get('end_year').value;
  return start !== null && end !== null && start > end
    ? {'startYearError': true}
    : null;
};


@Component({
  selector: 'app-data-intake-dialog',
  templateUrl: './data-intake-dialog.component.html',
  styleUrls: ['./data-intake-dialog.component.css']
})
export class DataIntakeDialogComponent implements OnInit {
  tags: string[];
  chapters: string[];
  types: IntakeType[] = [
    {value: 'Geospatial Data'},
    {value: 'Library Documents'},
    {value: 'Laboratory Data'}];

  date: Dates[] = [];

  dataIntakeForm = new FormGroup({
    id: new FormControl(),
    // environment: new FormControl('AUM'),
    // display_location: new FormControl('TBD'),
    // file_name: new FormControl('', Validators.required),
    submission_date: new FormControl(null, Validators.required),
    description: new FormControl('', Validators.required),
    data_provider_name: new FormControl(''),
    data_provider_email: new FormControl(''),
    data_provider_company: new FormControl(''),
    data_provider_phone: new FormControl(''),
    epa_provider_name: new FormControl('', Validators.required),
    epa_provider_email: new FormControl('', Validators.email),
    epa_provider_phone: new FormControl('', Validators.required),
    site_epa_id: new FormControl('', Validators.required),
    site_name: new FormControl('', Validators.required),
    intake_staff_fk: new FormControl(null),
    notes: new FormControl(),
    intake_storage: new FormControl(),
  });
  loading = false;

  fileToUpload: File = null;
  formData: FormData = new FormData();

  r;
  filename = null;
  startYear = 1980;
  endYear = new Date().getFullYear();

  aumService: AumService;


  constructor(public dialogRef: MatDialogRef<DataIntakeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog,
              public loadingService: LoadingService,
              // loginService: AuthService,
              http: HttpClient, private tokenExtractor: HttpXsrfTokenExtractor) {
    const headerName = 'X-CSRFToken';
    const token = this.tokenExtractor.getToken() as string;
    const header = {};
    header[headerName] = token;
    this.r = new Resumable({
      headers: token ? header : null,
      withCredentials: true,
      // headers: {'Authorization': `Bearer ${loginService.access_token}`},
      simultaneousUploads: 6,
      chunkSize: 2.5 * 1024 * 1024 // increased from default (1024 to reduce number of calls to service since we are seeing a big spike in CPU
    });
    for (let i = this.startYear; i <= this.endYear; i++) {
      this.date.push({value: i});
    }
    this.aumService = new AumService('aum/dataintake', http, loadingService);

  }


  ngOnInit() {
    this.dataIntakeForm.patchValue(this.data.dataIntakeEntry);
    if (this.data.dataIntakeEntry.tags === undefined) {
      this.data.dataIntakeEntry.tags = [];
    }
    if (this.data.dataIntakeEntry.chapters === undefined) {
      this.data.dataIntakeEntry.chapters = [];
    }
    this.loadingService.getLoadingStream().subscribe(value => {
      setTimeout(() => this.loading = value);
    });

    this.r.assignBrowse(document.getElementById('file_button'));
    const vm = this;
    this.r.on('fileAdded', function (file) {
      vm.filename = file.fileName;
    });

  }

  setFileForm(files: FileList) {
    const fileToUpload = files.item(0);
    this.formData.append('dump_file', fileToUpload, fileToUpload.name);
  }


  save() {
    this.loadingService.setLoading(true);
    if (this.dataIntakeForm.value.id) {
      this.aumService.put(this.dataIntakeForm.value.id, this.dataIntakeForm.value)
        .pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(response => {
          Object.assign(this.data.dataIntakeEntry, response);
          this.r.opts.target = `${environment.local_service_endpoint}/aum/dataintake/${this.data.dataIntakeEntry.id}/upload`;
          this.r.upload();
          // this.formData.append('data_intake', this.data.dataIntakeEntry.id);
          this.dialogRef.close({r: this.r, new: false});
        });
    } else {
      this.aumService.post(this.dataIntakeForm.value)
        .pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(response => {
          Object.assign(this.data.dataIntakeEntry, response);
          this.r.opts.target = `${environment.local_service_endpoint}/aum/dataintake/${this.data.dataIntakeEntry.id}/upload`;
          this.r.upload();
          // this.formData.append('data_intake', response.id);
          this.dialogRef.close({r: this.r, new: true});
        });
    }
  }

  setValue(value: string, field: string) {
    let option = {};
    option[field] = value;
    this.dataIntakeForm.patchValue(option);
  }

  warn() {
    this.dialog.open(ConfirmationDialogComponent,
      {
        width: '400px',
        data: {
          msg: 'Any spaces found in file names will be replaced with underscores. ' +
            '(e.g. "my shapefile.shp" will become "my_shapefile.shp") ' +
            'This may cause unexpected behaviors. Proceed?'
        }
      }).afterClosed().pipe(
        tap(confirmed => {
          if (confirmed) {
            this.save();
          }
        })
    ).subscribe();
  }
}
