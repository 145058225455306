import {EventEmitter, Injectable, Output} from '@angular/core';
import {DataIntakeDialogComponent} from '../data-intake-dialog/data-intake-dialog.component';
import {filter, flatMap, map, take, tap} from 'rxjs/operators';
import {interval} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {BaseService} from '../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../shared/services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class IntakeDialogService {
  aumService: BaseService;
  progress = {};

  constructor(public dialog: MatDialog, http: HttpClient, loading: LoadingService) {
    this.aumService = new BaseService('aum/dataintake', http, loading);
  }

  open(event, item) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DataIntakeDialogComponent, {
      width: '600px',
      height: '95%',
      data: {
        dataIntakeEntry: item
      },
      disableClose: true
    });

    return dialogRef.afterClosed().pipe(
      map(response => {
        if (response.new) {
          const copiedData = this.aumService.data.slice();
          copiedData.unshift(item); // changed to unshift so it gets add to front of array for more visibility
          this.aumService.dataChange.next(copiedData);
        }
        return response.r;
      }),
      filter(r => r !== undefined && r !== false && r.files.length > 0),
      flatMap(r => {
        item.processing_status = 'uploading';
        return interval(250).pipe(
          map(() => {
            this.progress[item.id] = r.progress() * 100;
            return this.progress[item.id];
          }),
          filter(progress => progress >= 100),
          take(1),
          tap(() => {
            delete this.progress[item.id];
            item.processing_status = 'pending';
          })
        );
      }),
    );
  }
}
