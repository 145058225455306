import { Injectable } from '@angular/core';
import {BaseService, SearchObject, Response} from '../shared/services/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface AgolItem {
  item_id: string;
  owner: string;
  created: string;
  modified: string;
  name: string;
  title: string;
  type: string;
  description: string;
  tags: string;
  snippet: string;
  access: string;
  display_access: string;
  number_of_views: number;
  has_mapservice: boolean;
  has_featureclass: boolean;
  url: string;
  source_url: string;
  map_service_location: string;
  library_location: string;
  groups: string[];
  allow_public: boolean;
}

export interface AgolItemsSearchObject extends SearchObject {
  title?: string;
  type?: string;
  owner?: string;
  url?: string;
  source_url?: string;
  gslib_fc?: string;
  serviceLayer?: number;
  data__service_item?: string;
  serviceLayer__map_service__id?: number;
  has_featureclass?: boolean;
  has_mapservice?: boolean;
  groups?: number;
  access?: string;
  owner_fk__is_r9_user?: boolean;
}

export interface Group {
  title: string;
  owner: string;
  snippet: string;
  description: string;
  user: number;
  tags: number[];
  members: number[];
}

export interface GroupSearchObject extends SearchObject {
  agol_item?: string;
}

export interface User {
  id: number;
  username: string;
  groups: string[];
}

@Injectable()
export class AgolItemsService extends BaseService {
  public itemtypes: Observable<any[]>;
  filter: AgolItemsSearchObject;
  select_groups: Observable<Group[]>;
  select_users: Observable<User[]>;
  access_choices: Observable<any[]>;

  constructor(http: HttpClient, loading: LoadingService) {
    super('agolitems', http, loading);
    this.filter = {page: 1};
    this.itemtypes = this.http.get<Response>(`/${this.base_url}/types`,
      {params: new HttpParams().set('page_size', '1000')})
      .pipe(map(response => response.results));
    this.select_groups = http.get<Response>(`/${this.base_url}/groups`,
      {params: new HttpParams().set('additional__included', 'true').set('page_size', '1000')})
      .pipe(map(response => response.results));
    this.select_users = http.get<Response>(`/${this.base_url}/users`,
      {params: new HttpParams().set('groups__additional__user_filter', 'true').set('page_size', '1000')})
      .pipe(map(response => response.results));
    this.access_choices = http.get<any[]>(`/${this.base_url}/access_choices`);

  }
  get_groups(group_filter: GroupSearchObject) {
    return this.http.get<Response>(`/${this.base_url}/groups`,
      {
        params: Object.entries(group_filter).reduce((params, [key, value]) => params.set(key, value), new HttpParams())
      });
  }
}
