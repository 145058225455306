<div class="details-container" *ngIf="featureClass | async as fc">
  <div style="flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
    margin-right: 30px;">
    <button mat-raised-button type="button" color="primary" [routerLink]="['/featureclasses']"
            [queryParams]="{'keepFilter': true}">
      <mat-icon style="margin-left: -10px;">keyboard_arrow_left</mat-icon>
      Back
    </button>
  </div>
  <mat-list>
    <mat-list-item>
      <div class="title-column">Database Name</div>
      <div>{{ fc.db_name }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Name</div>
      <div>{{ fc.name }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Category</div>
      <div>{{ fc.category }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Title</div>
      <div>{{ fc.title }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="padding-bottom: 13.5px">
      <div class="title-column">Allowable Access Level</div>
      <mat-form-field>
        <mat-select (change)="showSave = true" [(value)]="fc.access_level">
          <mat-option value="internal">Internal</mat-option>
          <mat-option value="public">Public</mat-option>
          <mat-option value="er_cloud">ER Cloud</mat-option>
          <mat-option value="reference">Reference</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button *ngIf="showSave" (click)="saveAccess(fc)">
        <mat-icon>save</mat-icon>
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Abstract</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <div>
          <display-check [value]="fc.has_abstract"></display-check>
        </div>
        <div *ngIf="fc.has_abstract" style="margin-left: 15px;" [innerHTML]="fc.description"></div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Metadata</div>
      <div style="display: flex; align-items: center; justify-content: space-around">
        <div style="display: flex; align-items: center; font-size: small;">
          <b>Last Updated &nbsp;</b>
          {{ fc.last_update_metadata | date:'mediumDate' }}
          <mat-icon *ngIf="!fc.last_update_metadata" style="color:red;" matTooltip="Date Missing">close
          </mat-icon>
        </div>&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: flex; align-items: center; font-size:small">
          <b>&nbsp; &nbsp;UUID &nbsp;</b>
          {{ fc.md_uuid }}
          <mat-icon *ngIf="!fc.has_md_uuid" style="color:red;" matTooltip="UUID Missing">close</mat-icon>
        </div>&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: flex; align-items: center; font-size:small">
          <b>&nbsp;&nbsp;Valid&nbsp;&nbsp;</b>
          <mat-icon *ngIf="fc.metadata_valid == 'pass'" style="color: green;">check</mat-icon>
          <mat-icon *ngIf="fc.metadata_valid == 'ok'" style="color: orange;" matTooltip="Missing recommended fields">
            warning
          </mat-icon>
          <mat-icon *ngIf="fc.metadata_valid == 'fail'" style="color: red;" matTooltip="Missing required fields">close
          </mat-icon>
          <button *ngIf="validation_status !== 'running'" mat-icon-button (click)="rerunValidation($event, fc.uuid)">
            <mat-icon>replay</mat-icon>
          </button>
          <mat-spinner diameter="25" *ngIf="validation_status === 'running'"></mat-spinner>
        </div>
      </div>

      <app-metadata-results style="display: flex; flex-direction: row; align-items: center; flex: 1;"
                            *ngIf="fc.metadata_valid === 'warning' || fc.metadata_valid === 'fail'"
                            [feature_class]="featureClass.asObservable()"></app-metadata-results>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Update Cycle</div>
      <div style="display: flex; align-items: center; justify-content: space-around">
        <div style="display: flex; align-items: center; font-size: small;">
          <b>Next Update &nbsp;</b>
          {{ fc.next_update | date:'mediumDate' }}
          <mat-icon
            *ngIf="fc.next_update_status === 'Date Missing' || fc.next_update_status === null"
            style="color:red;" matTooltip="Date Missing">report_problem
          </mat-icon>
          <mat-icon *ngIf="fc.next_update_status === 'Needs Review'" style="color:green;"
                    matTooltip="Needs Review">update
          </mat-icon>
          <mat-icon *ngIf="fc.next_update_status === 'Current'" style="color:green;" matTooltip="Current">
            check
          </mat-icon>
        </div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">WAF Status</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <div>
          <display-check [value]="fc.waf_status"></display-check>
        </div>
        <div *ngIf="fc.waf_status" style="margin-left:15px;">
          <a target="_blank" href="{{ fc.waf }}">{{ fc.waf }}</a>
        </div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Environment</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <mat-icon *ngIf="fc.is_region_9" matTooltip="Region 9">computer</mat-icon>
        <mat-icon *ngIf="fc.is_er_cloud" matTooltip="ER Cloud">cloud_queue</mat-icon>
        <mat-icon *ngIf="fc.is_ncc" matTooltip="NCC">public</mat-icon>
        <mat-icon *ngIf="fc.location === 'intake'" matTooltip="Superfund Intake">terrain</mat-icon>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">EDG Status</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <div>
          <display-check [value]="fc.edg_status"></display-check>
        </div>
        <div *ngIf="fc.edg_status" style="margin-left: 15px;">
          <a target="_blank" href="{{ fc.edg_link }}">{{ fc.edg_link }}</a>
        </div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Data.gov Status</div>
      <div style="flex: 1; display: flex; align-items: center;">
        <div>
          <display-check [value]="fc.datagov_status"></display-check>
        </div>
        <div *ngIf="fc.datagov_status" style="margin-left: 15px;">
          <a target="_blank" href="{{ fc.datagov_link }}">{{ fc.datagov_link }}</a>
        </div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Map Service</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1;">
        <display-check [value]="fc.has_mapservice"></display-check>
        <mat-expansion-panel style="flex:1; margin-left: 15px;" *ngIf="serviceLayersService.count > 0">
          <mat-expansion-panel-header>
            <mat-panel-title><b style="font-size: small;">Service Layers</b></mat-panel-title>
            <mat-panel-description>Total: {{ serviceLayersService.count }}</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-table #table [dataSource]="serviceLayersService.datasource" class="table">
            <ng-container matColumnDef="map_service">
              <mat-header-cell *matHeaderCellDef class="header-cell">Map Service</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a routerLink="/mapservices/{{row.map_service}}">{{ row.map_service_name }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="layer_index">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layer Index</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.layer_index }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="service_layer_name">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layer Name</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.service_layer_name }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['map_service', 'layer_index', 'service_layer_name']"
                            class="header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['map_service', 'layer_index', 'service_layer_name']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>

    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Geoplatform</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1;">
        <display-check [value]="fc.has_agol"></display-check>
        <mat-expansion-panel style="flex:1; margin-left: 15px;" *ngIf="agolItemsService.count > 0">
          <mat-expansion-panel-header>
            <mat-panel-title><b style="font-size: small">Items</b></mat-panel-title>
            <mat-panel-description>Total: {{ agolItemsService.count }}</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-table #table [dataSource]="agolItemsService.datasource" class="table">
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef class="header-cell">Title</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a routerLink="/geoplatformitems/{{row.item_id}}">{{ row.title }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef class="header-cell">Type</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.type }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="owner">
              <mat-header-cell *matHeaderCellDef class="header-cell">Owner</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.owner }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['title', 'type', 'owner']" class="header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['title', 'type', 'owner']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Last Checked</div>
      <div>
        <div>{{ fc.time_checked | date:'medium' }}</div>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item *ngIf="fc.uuid">
      <div class="title-column">Change Log</div>
      <change-log-inline style="display: flex; flex-direction: row; align-items: center; flex: 1;"
                         [id]="fc.uuid" [content_type]="featureClasses.content_type">
      </change-log-inline>
    </mat-list-item>
  </mat-list>
  <br><br>
</div>
