import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {BaseService, Response} from '../../shared/services/base.service';
import {LoadingService} from '../../shared/services/loading.service';


@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.css']
})
export class TagInputComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() tags: string[];

  tagsCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tagsService: BaseService;
  allTags: Observable<Response>;
    // for tag autocomplete... all may not be needed
  separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;

  @ViewChild('tagInput', {read: ElementRef}) tagInput: ElementRef;

  constructor(public http: HttpClient, public loadingService: LoadingService) {
    this.tagsService = new BaseService('aum/all_tags', http, loadingService);
    this.allTags = this.tagsService.getList();
  }

  ngOnInit() {
    this.filteredTags = this.tagsCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((tag: string | null) => this.tagsService.getList({search: tag})),
      map(response => response.results.filter(result => this.tags.indexOf(result.name) === -1)),
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.form.controls.tags.setValue(this.tags);
    this.tagsCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.form.controls.tags.setValue(this.tags);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.form.controls.tags.setValue(this.tags);
    this.tagsCtrl.setValue(null);
  }
}
