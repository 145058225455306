import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'display-check',
  templateUrl: './display-check.component.html',
  styleUrls: ['./display-check.component.css']
})
export class DisplayCheckComponent implements OnInit {
  @Input() value: boolean;
  @Input() icon: string;

  constructor() {
  }

  ngOnInit() {
  }

}
