import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {forkJoin, Observable, ReplaySubject, Subject} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {GeometryService} from '../services/geometry.service';
import {UserConfigService} from '../services/user-config.service';
import {MapService} from '../services/map.service';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnInit {
  _map_services: any[];
  _extent;
  geometry_service: GeometryService = new GeometryService();
  save_button_display = '';

  get map_services() {
    return this._map_services;
  }

  @Input()
  set map_services(map_services: any[]) {
    this.mapService.addLayers(map_services);
    this._map_services = map_services;
  }

  get extent() {
    return this._extent;
  }

  @Input()
  set extent(extent) {
    const buffered_polygon = this.geometry_service.bufferGeometry(extent, 20, 'miles');
    this.mapService.setExtent(buffered_polygon.extent);
    this._extent = buffered_polygon.extent;
  }

  @Input() base_map_id: ReplaySubject<string>;
  @Output() map_service = new EventEmitter();
  @Input() hide_save_button = false;
  // @Input() map: ReplaySubject<any>;
  // @Input() mapView: ReplaySubject<any>;
  // @Output() currentExtent = new EventEmitter<any>();
  //
  // WebMap;
  // MapView;
  // MapImageLayer;
  //
  // view;

  mapService: MapService;
  loading: boolean;

  constructor(public loginService: UserConfigService, public userConfig: UserConfigService) {
    // probably a better way to do this.
    // if (this.map === undefined) this.map = new ReplaySubject<any>();
    this.mapService = new MapService([]);
  }

  ngOnInit() {
    this.loading = true;
    // mostly here for backwards compatibility. for when base_map_id isn't set on input
    if (!this.base_map_id) {
      this.base_map_id = new ReplaySubject<string>();
      this.base_map_id.next(null);
    }
    this.base_map_id.pipe(
      take(1),
      tap(map_id => {
        this.mapService.init('mapDiv', map_id).then(() => {
          this.map_service.emit(this.mapService);
          setTimeout(() => this.loading = false, 2000);
        });
      })
    ).subscribe();

    this.base_map_id.subscribe(user => {
      // update map to this base map id
    });
    if (this.hide_save_button) {
      this.save_button_display = 'none';
    }
  }

}
