import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../shared/services/loading.service';
import {BaseService} from '../../shared/services/base.service';
import {MatDialog} from '@angular/material/dialog';
import {GenericDialogComponent} from '../../shared/generic-dialog/generic-dialog.component';
import {JsonPipe} from '@angular/common';
import {filter, flatMap, switchMap, take, takeWhile, tap} from 'rxjs/operators';
import {interval, Observable, ReplaySubject, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TagDialogComponent} from '../../tagging/tag-dialog/tag-dialog.component';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.css']
})
export class FilesListComponent implements OnInit, OnDestroy {
  @Input() display_fields: string[];
  @Input() search_filter: object;
  @Input() title: string;
  @Output() published_items = new EventEmitter<any>();
  @Input() intake_ready: Observable<void>;

  aumIndexedFiles: BaseService;
  getItemsSubscription;
  auth_token;

  constructor(public http: HttpClient, loadingService: LoadingService, public dialog: MatDialog) {
    this.aumIndexedFiles = new BaseService('aum/indexedfiles', http, loadingService);
    this.aumIndexedFiles.filter = {page: 1};
  }

  ngOnInit() {
    for (const key of Object.keys(this.search_filter)) {
      this.aumIndexedFiles.filter[key] = this.search_filter[key];
    }
    // keep checking items until all are no longer pending
    this.getItemsSubscription = this.getItems().subscribe();
    // rerun get items when intake is ready.  This will only happen if intake loads while processing or is reprocessed
    this.intake_ready.pipe(switchMap(() => this.getItems())).subscribe();
  }

  showPublishingError(message) {
    let formatted_message = '';
    if (Array.isArray(message)) {
      for (const msg of message) {
        formatted_message += `<p><b>${msg.error}</b>: ${msg.layers.join(', ')}</p>`;
      }
    } else {
      formatted_message += message;
    }
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '1000px',
      data: {title: 'Publishing Error(s)', content: formatted_message}
    });
  }

  ngOnDestroy(): void {
    this.getItemsSubscription.unsubscribe();
  }

  getDownloadURL(item): string {
    return `${environment.local_service_endpoint}/aum/indexedfiles/${item.id}/download`;
  }

  openTagDialog(item) {
    this.dialog.open(TagDialogComponent, {
      data: {id: item.id, tags: item.tags},
      width: '500px'
    });
  }

  getItems(): Observable<any> {
    return this.aumIndexedFiles.getItems().pipe(
      tap(results => this.published_items.emit(results.filter(item => item.status === 'completed'))),
      takeWhile(results => results.some(e => e.status === 'pending')),
      flatMap(() => {
        return interval(30000).pipe(
          flatMap(() => this.aumIndexedFiles.getItems()),
          takeWhile(results => results.some(e => e.status === 'pending'))
        );
      }));
  }
}
