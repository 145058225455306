import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChangelogService} from '../services/changelog.service';
import {TitleService} from '../services/title.service';
import {FeatureclassesService} from '../services/featureclasses.service';
import {AgolItemsService} from '../services/agolitems.service';
import {MapservicesService} from '../services/mapservices.service';
import {forkJoin, of, Subscription} from 'rxjs';
import {debounceTime, finalize, map, skip, startWith, switchMap, tap} from 'rxjs/operators';
import {ChangeLogDialogComponent} from '../change-log-dialog/change-log-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {LoadingService} from '../shared/services/loading.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit, OnDestroy {
  displayedColumns = ['date', 'analyst', 'feature_class', 'action', 'status', 'completion_date', 'last_updated', 'edit', 'delete'];
  content_types = {};
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);

  constructor(public changeLogService: ChangelogService, public route: ActivatedRoute, public router: Router,
              public titleService: TitleService, public featureClassService: FeatureclassesService,
              public agolItemsService: AgolItemsService, public mapServicesService: MapservicesService,
              public dialog: MatDialog, public loadingService: LoadingService) {

  }

  ngOnInit() {
    this.titleService.setTitle('Change Log');
    /*if (this.route.snapshot.queryParams.keepFilter || this.changeLogService.count === undefined) {
      this.changeLogService.filter = {page: 1};
      return this.changeLogService.getItems().subscribe();
    }*/
    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        // this.changeLogService.applyQueryParams(params);
    }),
    switchMap(() => {
      return this.changeLogService.getItems();
    })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.changeLogService.dataChange.pipe(tap(() => {
      this.changeLogService.updateQueryParams(this.changeLogService.filter, this.router, this.route);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.changeLogService.filter.search = search;
    return this.changeLogService.runSearch();
  }

  getContentType() {
    const options = [this.featureClassService.options(), this.agolItemsService.options(), this.mapServicesService.options()];
    return forkJoin(options).pipe(
      map(o => {
        this.content_types[o[0].content_type] = 'featurelasses';
        this.content_types[o[1].content_type] = 'geoplatformitems';
        this.content_types[o[2].content_type] = 'mapservices';
      }));
  }

  openChangeLogDialog(event, changeLogEntry) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ChangeLogDialogComponent, {
      width: '600px',
      data: {
        changeLogEntry,
        showItem: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  delete(e, entry) {
    e.stopPropagation();
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {msg: 'Are you sure you want to remove this change log entry?'}
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.loadingService.setLoading(true);
        this.changeLogService.delete(entry.id)
          .pipe(finalize(() => this.loadingService.setLoading(false))).subscribe();
      }
    });
  }

}
