import {NgModule} from '@angular/core';
import {Routes, RouterModule, Router} from '@angular/router';
import {RootComponent} from './root/root.component';
import {FeatureClassesComponent} from './featureclasses/featureclasses.component';
import {FeatureClassDetailsComponent} from './featureclasses/details/details.component';
import {MapserviceDetailsComponent} from './mapservices/details/details.component';
import {MapservicesComponent} from './mapservices/mapservices.component';
import {GeoPlatformDetailsComponent} from './geoplatform/details/details.component';
import {GeoplatformComponent} from './geoplatform/geoplatform.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {GlobalSearchResultsComponent} from './global-search-results/global-search-results.component';
import {ExternalServersComponent} from './external-servers/external-servers.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {DataIntakeComponent} from './aum/data-intake/data-intake.component';
import {DataIntakeDetailsComponent} from './aum/data-intake-details/data-intake-details.component';
import {AuthService} from 'auth';
import {environment} from '../environments/environment';

const routes: Routes = [
  {
    path: '', component: RootComponent, canActivateChild: [AuthService],
    data: {
      type: 'ptt',
      message: 'PTT Group'
    },
    children: [
      {path: 'featureclasses', component: FeatureClassesComponent},
      {path: 'featureclasses/:id', component: FeatureClassDetailsComponent},
      {path: 'mapservices', component: MapservicesComponent},
      {path: 'mapservices/:id', component: MapserviceDetailsComponent},
      {path: 'geoplatformitems', component: GeoplatformComponent},
      {path: 'geoplatformitems/:id', component: GeoPlatformDetailsComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'globalsearch', component: GlobalSearchResultsComponent},
      {path: 'externalservers', component: ExternalServersComponent},
      {path: 'changelog', component: ChangelogComponent},
      // {path: 'aum', component: DataIntakeComponent},
      // {path: 'aum/:id', component: DataIntakeDetailsComponent},
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    const currentRoutes = router.config;
    Object.keys(environment.intakes).forEach(intakeType => {
      currentRoutes[0].children.push(
      {
        path: intakeType.toLowerCase(), children: [
          {path: 'intake', component: DataIntakeComponent},
          {path: 'intake/:id', component: DataIntakeDetailsComponent},
          {path: '', redirectTo: 'intake', pathMatch: 'full'}
        ]
      }
    );
    });
    router.resetConfig(currentRoutes);
  }
}
