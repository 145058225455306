// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  service_url: 'https://dmt-api.itgis.innovateteam.com/api',
  local_service_endpoint: 'https://dmt-api.itgis.innovateteam.com/api',
  default_base_map: '',
  oauth_url: 'https://dmt-api.itgis.innovateteam.com/api/oauth2',
  arcgis_server_root: 'https://arcserver.innovateteam.com/arcgis',
  admin_url: 'https://dmt-api.itgis.innovateteam.com/api/admin/',
  portal_base_url: 'https://innovate.maps.arcgis.com',
  proxied_urls: [
    'https://innovate.maps.arcgis.com',
    'https://www.arcgis.com/sharing/rest',
    'https://services3.arcgis.com/oKenFxF8DE2Ek7Xt'
  ],
  intakes: {
    'uas': {title: 'UAS'}
  }
};
