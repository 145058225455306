import { Component, OnInit } from '@angular/core';
import {MapService, MapservicesService} from '../../services/mapservices.service';
import {TitleService} from '../../services/title.service';
import {AgolItem, AgolItemsSearchObject, AgolItemsService} from '../../services/agolitems.service';
import {ServiceLayerSearchObject, ServicelayersService} from '../../services/servicelayers.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {DataSource} from '@angular/cdk/collections';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'mapservice-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class MapserviceDetailsComponent implements OnInit {
  mapService: any = <MapService>{};

  item_inline_data: BehaviorSubject<AgolItem[]> = new BehaviorSubject([]);
  item_inline_datasource: InlineDataSource;
  item_inline_filter: AgolItemsSearchObject = {page: 1, page_size: 1000};
  item_inline_count: number = 0;

  service_inline_data: BehaviorSubject<AgolItem[]> = new BehaviorSubject([]);
  service_inline_datasource: InlineDataSource;
  service_inline_filter: ServiceLayerSearchObject = {page: 1};
  service_inline_count: number = 0;
  constructor(public mapServices: MapservicesService, public route: ActivatedRoute,
              public titleService: TitleService, public agolItemsService: AgolItemsService,
              public serviceLayersService: ServicelayersService) {
    this.item_inline_datasource = new InlineDataSource(this.item_inline_data);
    this.service_inline_datasource = new InlineDataSource(this.service_inline_data);
  }

  ngOnInit() {
    this.mapServices.get(this.route.snapshot.params.id).subscribe(response => {
      this.mapService = response;
      this.titleService.setTitle(`Map Service: ${this.mapService.name}`);

      this.item_inline_filter.serviceLayer__map_service__id = this.mapService.id;
      this.agolItemsService.getList(this.item_inline_filter).subscribe(response => {
        this.item_inline_data.next(response.results);
        this.item_inline_count = response.count;
      });
      this.service_inline_filter.map_service__id = this.mapService.id;
      this.service_inline_filter.ordering = 'layer_index';
      this.serviceLayersService.getList(this.service_inline_filter).subscribe(response => {
        this.service_inline_count = response.count;
        this.service_inline_data.next(response.results);
      });
    });
  }

}

class InlineDataSource extends DataSource<any> {
  constructor(private data: BehaviorSubject<AgolItem[]>) {
    super();
  }

  connect(): Observable<any[]> {
    return this.data;
  }

  disconnect() {
  }
}
