import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProcessingStatusComponent} from './processing-status/processing-status.component';

import {DataIntakeComponent} from './data-intake/data-intake.component';
import {DataIntakeDialogComponent} from './data-intake-dialog/data-intake-dialog.component';
import {DataIntakeDetailsComponent} from './data-intake-details/data-intake-details.component';
import {FeatureClassListComponent} from './feature-class-list/feature-class-list.component';
import {FilesListComponent} from './files-list/files-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TaggingModule} from '../tagging/tagging.module';
import {SharedModule} from '../shared/shared.module';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {CateogryInputComponent} from './cateogry-input/cateogry-input.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    ProcessingStatusComponent,
    DataIntakeComponent,
    DataIntakeDialogComponent,
    DataIntakeDetailsComponent,
    FeatureClassListComponent,
    FilesListComponent,
    CateogryInputComponent
  ],
  exports: [FilesListComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    RouterModule,
    MatPaginatorModule,
    MatSidenavModule,
    TaggingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    SharedModule,
    MatExpansionModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatSelectModule,
    MatAutocompleteModule
  ],
  entryComponents: [DataIntakeDialogComponent]
})
export class AumModule {
}
