<mat-expansion-panel style="flex: 1">
  <mat-expansion-panel-header>
    <mat-panel-title class="title-column">Geospatial Layers</mat-panel-title>
    <!--    <mat-panel-description>Total: {{ featureClassService.count }}</mat-panel-description>-->
  </mat-expansion-panel-header>
  <!-- todo: since removing paging need to make it so all records load for sure -->
  <mat-table #table [dataSource]="results" class="table">
    <ng-container matColumnDef="db_name">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Source database. Use the Database filter to view a specific database."
                       [matTooltipPosition]="'above'">Source
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark"> {{ row.db_name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="short_name">
      <mat-header-cell *matHeaderCellDef class="header-cell shortname"
                       matTooltip="Name of the feature class." [matTooltipPosition]="'above'">Feature Class
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell shortname" matTooltip="{{ row.short_name }}">
        <a [routerLink]="['/featureclasses/', row.uuid]">{{ row.short_name }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="metadata_valid">
      <mat-header-cell *matHeaderCellDef class="header-cell">Valid Metadata
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <mat-icon *ngIf="row.metadata_valid == 'pass'" style="color: green;">check</mat-icon>
        <mat-icon *ngIf="row.metadata_valid == 'ok'" style="cursor:pointer; color: orange;"
                  matTooltip="Missing recommended fields"
                  (click)="showMetadataError($event, row.uuid)">warning
        </mat-icon>
        <mat-icon *ngIf="row.metadata_valid == 'fail'" style="cursor:pointer; color: red;"
                  matTooltip="Missing required fields"
                  (click)="showMetadataError($event, row.uuid)">close
        </mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="has_abstract">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if an abstract is included in the feature class metadata. Does not indicate quality of text."
                       [matTooltipPosition]="'above'">Abstract
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.has_abstract" style="color:green;">check</mat-icon>
        <mat-icon *ngIf="!row.has_abstract" style="color:red;">close</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="has_md_uuid">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                       matTooltip="Indicates if a UUID is included in the feature class metadata. This UUID is used to determine if the metadata record has been synced to EDG."
                       [matTooltipPosition]="'above'">Metadata UUID
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.has_md_uuid" style="color:green;">check</mat-icon>
        <mat-icon *ngIf="!row.has_md_uuid" style="color:red;">close</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="waf_status">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if the metadata in the feature class has been synced to the WAF. The title of the metadata must match the XML file name to be true."
                       [matTooltipPosition]="'above'">WAF XML
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.waf_status" style="color:green;">check</mat-icon>
        <mat-icon *ngIf="!row.waf_status" style="color:red;">close</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="edg_status">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if the metadata for the feature class has been synced to EDG using the UUID in the feature class."
                       [matTooltipPosition]="'above'">EDG Record
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <display-check [value]="row.edg_status"></display-check>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="datagov_status">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if the metadata for the feature class has been synced to Data.gov"
                       [matTooltipPosition]="'above'">Data.gov
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <display-check [value]="row.datagov_status"></display-check>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="has_mapservice">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if the feature class is used in an internal Region 9 map service. Click into the record to see the service(s)."
                       [matTooltipPosition]="'above'">Map Service
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.has_mapservice" style="color:green;">check</mat-icon>
        <mat-icon *ngIf="!row.has_mapservice" style="color:red;">close</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="has_agol">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates if the feature class is used in any GeoPlatform items. Click into the record to see the item(s)."
                       [matTooltipPosition]="'above'">GeoPlatform
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.has_agol" style="color:green;">check</mat-icon>
        <mat-icon *ngIf="!row.has_agol" style="color:red;">close</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="time_checked">
      <mat-header-cell *matHeaderCellDef class="header-cell"
                       matTooltip="Indicates if the most recent status refresh." [matTooltipPosition]="'above'">
        Checked
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        {{ row.time_checked | date:'medium' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="er_cloud">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates the ArcGIS Server environment in which the map service was published (Region 9, ER Cloud, NCC). Hover over the icon to see details."
                       [matTooltipPosition]="'above'">Environment
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.is_er_cloud" style="color:#202020;" matTooltip="ER Cloud">cloud_queue</mat-icon>
        <mat-icon *ngIf="row.is_region_9" style="color:#202020;" matTooltip="Region 9">computer</mat-icon>
        <mat-icon *ngIf="row.is_ncc" style="color:#202020;" matTooltip="NCC">public</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="next_update_status">
      <mat-header-cell *matHeaderCellDef class="header-cell checkmark"
                       matTooltip="Indicates the update cycle status for the feature class."
                       [matTooltipPosition]="'above'">Update Cycle
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell checkmark">
        <mat-icon *ngIf="row.next_update_status === 'Date Missing' || row.next_update_status === null"
                  style="color:red;"
                  matTooltip="Date Missing">report_problem
        </mat-icon>
        <mat-icon *ngIf="row.next_update_status === 'Needs Review'" style="color:orange;" matTooltip="Needs Review">
          update
        </mat-icon>
        <mat-icon *ngIf="row.next_update_status === 'Current'" style="color:green;" matTooltip="Current">check
        </mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="service_url">
      <mat-header-cell *matHeaderCellDef class="header-cell">Map Service URL</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <a *ngIf="row.map_service_url" [href]="row.map_service_url" target="_blank">Service URL</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="item_url">
      <mat-header-cell *matHeaderCellDef class="header-cell">GeoPlatform Item</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <a *ngIf="row.agol_item_url" [href]="row.agol_item_url" target="_blank">Item URL</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="header-cell">Publishing Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <div *ngIf="statuses[row.indexed_file] | async as s">
          <mat-icon *ngIf="s === 'completed'" style="color: green;">check</mat-icon>
          <mat-icon *ngIf="s === 'error'" style="color: red;">close</mat-icon>
          <!-- todo: figure out error handling for portal published service -->
          <!--        <mat-icon *ngIf="row.mxd_published === 'error'" style="color: red; cursor: pointer"-->
          <!--                  matTooltip="Click for details" (click)="showPublishingError(row.display_publishing_results)">close-->
          <!--        </mat-icon>-->
          <mat-progress-spinner mode="indeterminate"
                                *ngIf="['processing', 'pending'].includes(s)"
                                [diameter]="25"
                                style="margin-left: 14px;"></mat-progress-spinner>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="open_in_new">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell" (click)="$event.stopPropagation()" matTooltip="Open in new tab.">
        <a routerLink="/featureclasses/{{row.uuid}}" target="_blank">
          <mat-icon>open_in_new</mat-icon>
        </a>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></mat-row>
  </mat-table>
  <mat-paginator [length]="featureClassService.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="featureClassService.getPage($event)"
                 [pageIndex]="featureClassService.filter.page-1"></mat-paginator>

</mat-expansion-panel>
