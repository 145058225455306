<div class="details-container">
  <div style="flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
    margin-right: 30px;">
    <button mat-raised-button type="button" color="accent" style="margin-right: 15px;" *ngIf="agolItem.allow_public"
            (click)="toggleIntranetAccess()">Disable Intranet Access</button>
    <button mat-raised-button type="button" color="warn" style="margin-right: 15px;" *ngIf="agolItem.allow_public === false"
            (click)="toggleIntranetAccess()">Allow Intranet Access</button>
    <button mat-raised-button type="button" color="primary" routerLink="/geoplatformitems" [queryParams]="{'keepFilter':true}">
      <mat-icon style="margin-left: -10px;">keyboard_arrow_left</mat-icon>
      Back
    </button>
  </div>
  <mat-list>
    <mat-list-item>
      <div class="title-column">Type</div>
      <div>{{ agolItem.type }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Description</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">
        <mat-expansion-panel style="flex:1;">
          <mat-expansion-panel-header>
            <mat-panel-title><b style="font-size: small;">Summary</b> &nbsp;{{agolItem.snippet}}</mat-panel-title>
            <!--<mat-panel-description>{{geoplatformItem.snippet}}</mat-panel-description>-->
          </mat-expansion-panel-header>
          <!--<br> https://angular.io/guide/security#sanitization-and-security-contexts-->
          <div [innerHTML]="agolItem.description"></div>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Tags</div>
      <div>{{ agolItem.tags }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Owner</div>
      <div>{{ agolItem.owner }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item style="height: unset; min-height: 48px;">
      <div class="title-column">Access</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1;">
        <div>{{ agolItem.display_access }}</div>

        <mat-accordion style="flex: 1;">
          <mat-expansion-panel style="flex:1; margin-left: 15px;">
            <mat-expansion-panel-header>
              <mat-panel-title>Groups</mat-panel-title>
              <mat-panel-description>Total: {{groups_inline_count}}</mat-panel-description>
            </mat-expansion-panel-header>
            <mat-table #table [dataSource]="groups_inline_datasource" class="table groups-table">
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef class="header-cell">Title</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">
                  {{ row.title }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="owner">
                <mat-header-cell *matHeaderCellDef class="header-cell">Owner</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">
                  {{ row.owner }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="snippet">
                <mat-header-cell *matHeaderCellDef class="header-cell">Snippet</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">
                  {{ row.snippet }}
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="['title', 'owner', 'snippet']"
                class="header-row"></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['title', 'owner', 'snippet']"></mat-row>
            </mat-table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Created</div>
      <div>{{ agolItem.created | date:'MMM d, y, h:mm a' }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Last Modified</div>
      <div>{{ agolItem.modified | date:'MMM d, y, h:mm a' }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div class="title-column">Views</div>
      <div>{{ agolItem.number_of_views }}</div>
      <div style="padding-left: 25px;">
        <a href="https://epa.maps.arcgis.com/home/item.html?id={{agolItem.item_id}}" target="_blank">
          <button mat-raised-button color="primary">View in GeoPlatform</button>
        </a>
      </div>
    </mat-list-item>
    <mat-divider *ngIf="agolItem.type === 'Web Map'"></mat-divider>
    <mat-list-item *ngIf="agolItem.type === 'Web Map'" style="height: unset; min-height: 48px;">
      <div class="title-column">Web Map Layers</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">
        <mat-accordion style="flex: 1;">
          <mat-expansion-panel *ngFor="let data of item_data"
                               (click)="getServiceLayers(data)" style="flex:1; margin-left: 15px;">
            <mat-expansion-panel-header>
              <mat-panel-title><b style="font-size: small;">{{data.title}}</b></mat-panel-title>
              <mat-panel-description>
                {{data.layertype}}
                <div style="display: flex; align-items: center">Map Service:
                  <mat-icon *ngIf="data.map_service_location === 'region_9'" style="color:green;" matTooltip="Region 9">
                    computer
                  </mat-icon>
                  <mat-icon *ngIf="data.map_service_location === 'er_cloud'" style="color:green;" matTooltip="ER Cloud">
                    cloud_queue
                  </mat-icon>
                  <mat-icon *ngIf="data.map_service_location === 'ncc'" style="color:green;" matTooltip="NCC">public
                  </mat-icon>
                  <mat-icon *ngIf="data.map_service_location === ''" style="color:red;">close</mat-icon>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            Url &nbsp;{{data.url}}
            <mat-table #table [dataSource]="service_inline_datasource" class="table"
                       *ngIf="data.serviceLayer.length > 0">
              <ng-container matColumnDef="map_service_name">
                <mat-header-cell *matHeaderCellDef class="header-cell">Service Name</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">
                  <a routerLink="/mapservices/{{row.map_service}}">{{ row.map_service_name }}</a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="service_layer_name">
                <mat-header-cell *matHeaderCellDef class="header-cell">Layer Name</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">{{ row.service_layer_name }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="feature_class">
                <mat-header-cell *matHeaderCellDef class="header-cell">Feature Class</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell">
                  <a *ngIf="row.gdb_status !== null" routerLink="/featureclasses/{{row.gdb_status}}">
                    {{ row.feature_class }}
                  </a>
                  <span *ngIf="row.gdb_status === null">{{ row.feature_class }}</span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="gdb_status">
                <mat-header-cell *matHeaderCellDef class="header-cell checkmark">Enterprise Library</mat-header-cell>
                <mat-cell *matCellDef="let row" class="cell checkmark">
                  <display-check [value]="row.gdb_status"></display-check>
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="['map_service_name', 'service_layer_name', 'feature_class','gdb_status']"
                class="header-row"></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['map_service_name', 'service_layer_name','feature_class', 'gdb_status']"></mat-row>
            </mat-table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-list-item>
    <mat-divider *ngIf="item_inline_count > 0"></mat-divider>

    <mat-list-item *ngIf="item_inline_count > 0" style="height: unset; min-height: 48px;">
      <div class="title-column">GeoPlatform Usages</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">

        <mat-expansion-panel style="flex:1; margin-left: 15px;">
          <mat-expansion-panel-header>
            <mat-panel-title><b style="font-size: small">Items</b></mat-panel-title>
            <mat-panel-description>Total: {{ item_inline_count }}</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-table #table [dataSource]="item_inline_datasource" class="table">
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef class="header-cell">Title</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a routerLink="/geoplatformitems/{{row.item_id}}">{{ row.title }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef class="header-cell">Type</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.type }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="owner">
              <mat-header-cell *matHeaderCellDef class="header-cell">Owner</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.owner }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="modified">
              <mat-header-cell *matHeaderCellDef class="header-cell">Modified</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.modified | date:'MMM d, y, h:mm a' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['title','type','owner','modified']"
                            class="header-row"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: ['title','type','owner','modified']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <mat-divider *ngIf="agolItem.url"></mat-divider>
    <mat-list-item *ngIf="agolItem.url">
      <div class="title-column">Url</div>
      <div>{{ agolItem.url }}</div>
    </mat-list-item>
    <mat-divider *ngIf="agolItem.source_url"></mat-divider>
    <mat-list-item *ngIf="agolItem.source_url">
      <div class="title-column">Source Url</div>
      <div>{{ agolItem.source_url }}</div>
    </mat-list-item>
    <mat-divider *ngIf="agolItem.type !== 'Web Map' && service_inline_count > 0"></mat-divider>
    <mat-list-item *ngIf="agolItem.type !== 'Web Map' && service_inline_count > 0" style="height: unset; min-height: 48px;">
      <div class="title-column">Map Services</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1;">
        <mat-icon *ngIf="agolItem.map_service_location === 'region_9'" style="color:green;" matTooltip="Region 9">
          computer
        </mat-icon>
        <mat-icon *ngIf="agolItem.map_service_location === 'er_cloud'" style="color:green;" matTooltip="ER Cloud">
          cloud_queue
        </mat-icon>
        <mat-icon *ngIf="agolItem.map_service_location === 'ncc'" style="color:green;" matTooltip="NCC">public
        </mat-icon>
        <mat-expansion-panel style="flex:1; margin-left: 38px;">
          <mat-expansion-panel-header>
            <mat-panel-title style="font-size: small"><b>Service Layers</b></mat-panel-title>
            <mat-panel-description>Total: {{ service_inline_count }} (Click for Enterprise Library Details)
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-table #table [dataSource]="service_inline_datasource" class="table">
            <ng-container matColumnDef="map_service_name">
              <mat-header-cell *matHeaderCellDef class="header-cell">Service Name</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a routerLink="/mapservices/{{row.map_service}}">{{ row.map_service_name }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="service_layer_name">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layer Name</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.service_layer_name }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="feature_class">
              <mat-header-cell *matHeaderCellDef class="header-cell">Feature Class</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <a *ngIf="row.gdb_status !== null" routerLink="/featureclasses/{{row.gdb_status}}">
                  {{row.feature_class }}
                </a>
                <span *ngIf="row.gdb_status === null">{{ row.feature_class }}</span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gdb_status">
              <mat-header-cell *matHeaderCellDef class="header-cell checkmark">Enterprise Library</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell checkmark">
                <display-check [value]="row.gdb_status"></display-check>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['map_service_name', 'service_layer_name', 'feature_class','gdb_status']"
                            class="header-row"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: ['map_service_name', 'service_layer_name','feature_class', 'gdb_status']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <!--<mat-divider></mat-divider>-->
    <!--<mat-list-item>-->
    <!--<div class="title-column">R9 Map Service:</div>-->
    <!--<div style="display: flex; flex-direction: row; align-items: center; flex: 1;">-->
    <!--<display-check [value]="agolItem.has_mapservice"></display-check>-->
    <!--</div>-->
    <!--</mat-list-item>-->
    <mat-divider *ngIf="agolItem.type === 'Web Map'"></mat-divider>
    <mat-list-item *ngIf="agolItem.type === 'Web Map'" style="height: unset; min-height: 48px;">
      <div class="title-column">Web Map Backups</div>
      <div style="display: flex; flex-direction: row; align-items: center; flex: 1; align-self: flex-start;">
        <mat-expansion-panel style="flex:1; margin-left: 15px;" *ngIf="agolitemBackupsService.count > 0">
          <mat-expansion-panel-header>
            <mat-panel-title><b style="font-size: small;">Web Map Backups</b></mat-panel-title>
            <mat-panel-description>Total: {{ agolitemBackupsService.count }}</mat-panel-description>
          </mat-expansion-panel-header>
          <mat-table #table [dataSource]="agolitemBackupsService.datasource" class="table">
            <ng-container matColumnDef="backupdate">
              <mat-header-cell *matHeaderCellDef class="header-cell">Date</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.backupdate | date:'MMM d, y, h:mm a' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataitemcount">
              <mat-header-cell *matHeaderCellDef class="header-cell">Layers</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">{{ row.dataitemcount }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="item_backup_json">
              <mat-header-cell *matHeaderCellDef class="header-cell">Map Info</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <button mat-raised-button color="accent" (click)="openViewJsonDialog(row.item_backup_json)">View JSON
                </button>
                <!--<mat-expansion-panel>-->
                <!--<mat-expansion-panel-header>-->
                <!--<mat-panel-title>View JSON</mat-panel-title>-->
                <!--</mat-expansion-panel-header>-->
                <!--<div style="width: 400px;">{{ row.item_backup_json }}</div>-->
                <!--</mat-expansion-panel>-->
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="itemdata_backup_json">
              <mat-header-cell *matHeaderCellDef class="header-cell">Map Data</mat-header-cell>
              <mat-cell *matCellDef="let row" class="cell">
                <button mat-raised-button color="accent" (click)="openViewJsonDialog(row.itemdata_backup_json)">View
                  JSON
                </button>
                <!--<mat-expansion-panel>-->
                <!--<mat-expansion-panel-header>-->
                <!--<mat-panel-title>View JSON</mat-panel-title>-->
                <!--</mat-expansion-panel-header>-->
                <!--<div style="width: 400px;">{{ row.itemdata_backup_json }}</div>-->
                <!--</mat-expansion-panel>-->
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="['backupdate','dataitemcount', 'item_backup_json', 'itemdata_backup_json']"
              class="header-row"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: ['backupdate','dataitemcount','item_backup_json', 'itemdata_backup_json']"></mat-row>
          </mat-table>
        </mat-expansion-panel>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item *ngIf="agolItem.item_id" style="height: unset; min-height: 48px;">
      <div class="title-column">Change Log</div>
      <change-log-inline style="display: flex; flex-direction: row; align-items: center; flex: 1;"
                         [id]="agolItem.item_id" [content_type]="agolitemsService.content_type">
      </change-log-inline>
    </mat-list-item>
  </mat-list>
</div>
