import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ExternalServersService} from '../services/externalservers.service';
import {TitleService} from '../services/title.service';
import { MatDialog } from '@angular/material/dialog';
import {ExternalServerDialogComponent} from '../external-server-dialog/external-server-dialog.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {LoadingService} from '../shared/services/loading.service';

import {Subscription} from 'rxjs';
import {debounceTime, finalize, map, skip, startWith, switchMap, tap} from 'rxjs/operators';


@Component({
  selector: 'app-external-servers',
  templateUrl: './external-servers.component.html',
  styleUrls: ['./external-servers.component.css']
})
export class ExternalServersComponent implements OnInit, OnDestroy {
  public displayedColumns = ['organization', 'office', 'site_url', 'status', 'notes', 'edit', 'delete'];
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);

  constructor(public externalServers: ExternalServersService, public titleService: TitleService,
              public route: ActivatedRoute, public router: Router,
              public dialog: MatDialog, public loadingService: LoadingService) {
  }

  ngOnInit() {
    this.titleService.setTitle('External Sources');
    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        this.externalServers.applyQueryParams(params);
    }),
    switchMap(() => {
      return this.externalServers.getItems();
    })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.externalServers.dataChange.pipe(tap(() => {
      this.externalServers.updateQueryParams(this.externalServers.filter, this.router, this.route);
    })).subscribe();

    this.externalServers.getItems().subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.externalServers.filter.search = search;
    return this.externalServers.runSearch();
  }

  openExternalServerDialog(event, external_server) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ExternalServerDialogComponent, {
      width: '600px',
      data: {
        external_server
      }
    });
  }

  delete(e, entry) {
    e.stopPropagation();
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {msg: 'Are you sure you want to remove this external server entry?'}
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.loadingService.setLoading(true);
        this.externalServers.delete(entry.id)
          .pipe(finalize(() => this.loadingService.setLoading(false)))
          .subscribe();
      }
    });
  }
}
