import {Component, OnInit} from '@angular/core';
import {GlobalsearchService} from '../services/globalsearch.service';
import {LoadingService} from '../shared/services/loading.service';
import {TitleService} from '../services/title.service';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

@Component({
  selector: 'app-global-search-results',
  templateUrl: './global-search-results.component.html',
  styleUrls: ['./global-search-results.component.css']
})
export class GlobalSearchResultsComponent implements OnInit {
  displayedColumns = ['item_type', 'name', 'description', 'location'];
  content_types: any;

  constructor(public globalSearchService: GlobalsearchService, public loadingService: LoadingService,
              public titleService: TitleService, public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.setTitle('Search Results');
    this.globalSearchService.loadContentTypes();
    if (this.route.snapshot.queryParams.search) {
      this.globalSearchService.filter = {
        page: 1,
        search: this.route.snapshot.queryParams.search
      };
      this.globalSearchService.getItems().subscribe();
    }


  }


}
