import { Injectable } from '@angular/core';
import {BaseService, SearchObject} from './base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';

export interface AgolitemData {
  title: string;
  layertype: string;
  url: string;
  item:string;
  serviceLayer: number[];
  map_service_location: string;
}

export interface AgolItemDataSearchObject extends SearchObject {
  item?: string;
}

@Injectable()
export class AgolitedataService extends BaseService {

  constructor(http: HttpClient, loading: LoadingService) {
    super('agolitems/data', http, loading);
  }

}
