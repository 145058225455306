<div style="display: flex; flex-direction: column; justify-content: space-around">
  <form novalidate [formGroup]="changeLogForm" style="width: 100%;">
    <mat-form-field>
      <input matInput type="date" formControlName="date" placeholder="Date">
    </mat-form-field>
    <mat-form-field *ngIf="data.showItem">
      <input matInput type="text" formControlName="object_display" placeholder="Item" [matAutocomplete]="auto" name="object_display">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let option of results" [value]="option.id">{{option.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput matTextareaAutosize formControlName="action" placeholder="Action"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Status" formControlName="status">
        <mat-option *ngFor="let status of changeLogService.status_choices" [value]="status.value">
          {{ status.display_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput matTextareaAutosize formControlName="comments" placeholder="Comments"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Analyst" formControlName="analyst" #analyst>
        <mat-option *ngFor="let analyst of changeLogService.all_analysts | async" [value]="analyst.id">
          {{ analyst.name }}
        </mat-option>
      </mat-select>
      <button mat-button type="button" color="accent" *ngIf="changeLogForm.value.analyst !== null"
              class="input-clear-button" (click)="clearField($event, 'analyst')">
        <mat-icon>close</mat-icon>
    </button>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="date" formControlName="completion_date" placeholder="Completion Date">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="date" formControlName="last_updated" placeholder="Last Updated" readonly>
    </mat-form-field>
    <div style="display: flex; flex-direction: row; justify-content: flex-end;">
      <button mat-raised-button color="accent" (click)="dialogRef.close(false)" style="margin-right: 15px">Cancel
      </button>
      <button mat-raised-button [disabled]="!changeLogForm.valid" color="primary" (click)="save()">Save</button>
    </div>
  </form>
</div>
