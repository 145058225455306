import {Component, EventEmitter, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {AddServiceDialogComponent} from '../add-service-dialog/add-service-dialog.component';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-add-service-button',
  templateUrl: './add-service-button.component.html',
  styleUrls: ['./add-service-button.component.css']
})
export class AddServiceButtonComponent {
  @Output() map_services: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(public dialog: MatDialog) {
  }

  openDataDialog(event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      width: '60%',
      height: '90%',
      disableClose: true
    });

    dialogRef.afterClosed().pipe(
      tap(map_services => {
        this.map_services.next(map_services);
      })
    ).subscribe();
  }

}
