import {Injectable} from '@angular/core';
import {BaseService, SearchObject, Response} from '../shared/services/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';
import {Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';

export interface ChangeLog {
  id: number;
  date: string;
  feature_class: number;
  action: string;
  comments: string;
  analyst: number;
  completion_date: string;
  object_placeholder: string;
}

export interface ChangeLogSearchObject extends SearchObject {
  feature_class?: string;
}

@Injectable()
export class ChangelogService extends BaseService {
  public all_analysts: Observable<any>;
  public status_choices: {value: string, display_name: string}[];

  constructor(http: HttpClient, loading: LoadingService) {
    super('changelog', http, loading, ['analysts', 'statuses']);
    this.all_analysts = http.get<Response>(`/${this.base_url}/all_analysts`,
      {params: new HttpParams().set('additional__included', 'true').set('page_size', '1000')}
    ).pipe(map((response) => {
      return response;
      }),
      share()
    );
    this.options().pipe(
      tap(response => {
        this.status_choices = response.actions.POST.status.choices;
        const incompleteStatuses = this.status_choices.filter(status => status.value !== 'completed').map(s => s.value);
        this.filter = {page: 1, status__in: incompleteStatuses};
      })
    ).subscribe();
  }
}
