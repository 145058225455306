import {Component, Input, OnInit} from '@angular/core';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {ChangeLogDialogComponent} from '../change-log-dialog/change-log-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {LoadingService} from '../shared/services/loading.service';
import {ChangelogService} from '../services/changelog.service';
import {ReplaySubject} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'change-log-inline',
  templateUrl: './change-log-inline.component.html',
  styleUrls: ['./change-log-inline.component.css']
})
export class ChangeLogInlineComponent implements OnInit {
  @Input() content_type: ReplaySubject<number>;
  @Input() id: number | string;

  constructor(public dialog: MatDialog, public loadingService: LoadingService,
              public changeLogService: ChangelogService) {
  }

  ngOnInit() {
    this.content_type.subscribe(content_type => {
      this.changeLogService.filter.object_id = this.id;
      this.changeLogService.filter.content_type = content_type;
      this.changeLogService.getItems().subscribe();
    })

  }

  openChangeLogDialog(changeLogEntry) {
    let dialogRef = this.dialog.open(ChangeLogDialogComponent, {
      width: '600px',
      data: {
        changeLogEntry,
        showItem: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    })
  }

  deleteChangeLog(e, log_entry) {
    e.stopPropagation();
    let dialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {msg: 'Are you sure you want to remove this log entry?'}
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.loadingService.setLoading(true);
        this.changeLogService.delete(log_entry.id).pipe(
          finalize(() => this.loadingService.setLoading(false))).subscribe();
      }
    })
  }
}
