<div class="main-view">
  <div class="list-header">
    <mat-form-field style="width:250px;">
      <input matInput type="text" placeholder="Search" [(ngModel)]="globalSearchService.filter.search"
             (keyup.enter)="globalSearchService.runSearch()" name="search"/>
      <button style="position:absolute; top:-5px; right:0;" type="button" mat-icon-button
              (click)="globalSearchService.filter.search = ''; globalSearchService.runSearch()"
              *ngIf="globalSearchService.filter.search">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="list-container">
    <mat-table #table [dataSource]="globalSearchService.datasource" class="table" matSort
               (matSortChange)="globalSearchService.sortTable($event)">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Name</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell"> {{ row.name }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Description</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell"> {{ row.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef class="header-cell checkmark" matTooltip="Indicates the ArcGIS Server environment in which the map service was published (Region 9, ER Cloud, NCC), where applicable. Hover over the icon to see details.
            Hover over the icon to see environment details." [matTooltipPosition]="'above'">Location
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell checkmark">
          <mat-icon *ngIf="row.location === 'region_9'" style="color:#202020;" matTooltip="Region 9">
            computer
          </mat-icon>
          <mat-icon *ngIf="row.location === 'er_cloud'" style="color:#202020;" matTooltip="ER Cloud">
            cloud_queue
          </mat-icon>
          <mat-icon *ngIf="row.location === 'ncc'" style="color:#202020;" matTooltip="NCC">public
          </mat-icon>
          <mat-icon *ngIf="row.location === ''" style="color:red;"
                    matTooltip="Item does not use R9, ER, or NCC services.">close
          </mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="item_type">
        <mat-header-cell *matHeaderCellDef class="header-cell checkmark">Type</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell checkmark">
          <mat-icon *ngIf="row.item_type === 'FC'" style="color:#202020;" matTooltip="Feature Class">pin_drop</mat-icon>
          <mat-icon *ngIf="row.item_type === 'MS'" style="color:#202020;" matTooltip="Map Service">layers</mat-icon>
          <mat-icon *ngIf="row.item_type === 'GP'" style="color:#202020;" matTooltip="GeoPlatform">language</mat-icon>
        </mat-cell>
      </ng-container>
      <mat-header-row style="padding-left:0" *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer; padding-left:0"
               routerLink="/{{ globalSearchService.content_types[row.content_type] }}/{{row.id}}?previousState=changelog"></mat-row>

    </mat-table>
    <mat-paginator [length]="globalSearchService.count" [pageSize]="25"
                   [pageSizeOptions]="[10, 25, 50, 100]"
                   (page)="globalSearchService.getPage($event)"
                   [pageIndex]="globalSearchService.filter.page-1"></mat-paginator>
  </div>
</div>
