import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseService} from '../../shared/services/base.service';
import {LoadingService} from '../../shared/services/loading.service';
import {FormControl, Validators} from '@angular/forms';

export interface Intake {
  id: string;
  processing_status: string;
  submission_date: string;
  description: string;
  data_provider_name: string;
  data_provider_email: string;
  data_provider_company: string;
  data_provider_phone: string;
  epa_provider_name: string;
  epa_provider_email: string;
  epa_provider_phone: string;
  site_epa_id: string;
  site_name: string;
  // display_location: string;
  file_size: number;
  file_name: string;
  notes: string;
}

@Injectable()
export class AumService extends BaseService {
  all_tags: Observable<string[]>;
  filter: any;

  constructor(public base_url: string, public http: HttpClient, loading: LoadingService) {
    super(base_url, http, loading);
    this.filter = {page: 1};

    this.all_tags = this.http.get<string[]>(`/${this.base_url}/all_tags`);
  }

}
