<mat-expansion-panel style="flex:1; margin-left: 15px;" [expanded]="open">
  <mat-expansion-panel-header>
    <mat-panel-title>Validation Errors</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-table #table [dataSource]="resultsService.datasource" class="table" matSort
             (matSortChange)="resultsService.sortTable($event)">
    <ng-container matColumnDef="line_number">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Line #</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> {{ row.line_number }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="element">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Element Xpath</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> {{ row.element }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rule">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Rule</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> {{ row.rule }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="url">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">URL</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> <a target="_blank" [href]="row.url">More Details</a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Description</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="level">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Level</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.level}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="display_columns" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: display_columns;" class="row"></mat-row>
  </mat-table>
  <mat-paginator [length]="resultsService.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="resultsService.getPage($event)"
                 [pageIndex]="resultsService.filter.page-1"></mat-paginator>
</mat-expansion-panel>
