import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../shared/services/breadcrumb.service';
import {MatDialog} from '@angular/material/dialog';
import {BaseService} from '../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../shared/services/loading.service';

import {IntakeDialogService} from '../services/intake-dialog.service';
import {TitleService} from '../../services/title.service';
import {Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {debounceTime, map, skip, startWith, switchMap, tap} from "rxjs/operators";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-data-intake',
  templateUrl: './data-intake.component.html',
  styleUrls: ['./data-intake.component.css']
})
export class DataIntakeComponent implements OnInit, OnDestroy {
  displayedColumns = ['description', 'submission_date', 'site_name', 'data_provider_name', 'processing_status', 'edit'];
  aumService: BaseService;
  intakeDialog: IntakeDialogService;
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);
  intake_storage: string;

  constructor(breadcrumbService: BreadcrumbService, public dialog: MatDialog, public http: HttpClient,
              loading: LoadingService, private titleService: TitleService, public route: ActivatedRoute, public router: Router) {
    breadcrumbService.setBreadcrumb([
      {label: 'Home', url: '/home'},
      {label: 'Data Warehouse', url: '/intake'}
    ]);
    this.aumService = new BaseService('aum/dataintake', http, loading);
    this.intakeDialog = new IntakeDialogService(dialog, http, loading);
  }

  ngOnInit() {
    // lock to this storage route
    this.intake_storage = this.route.parent.snapshot.url[0].path;
    this.aumService.filter.intake_storage = this.intake_storage;

    this.titleService.setTitle(`${environment.intakes[this.intake_storage].title} Intake`);

    /*this.aumService.filter = {page: 1, ordering: '-date_received'};
    this.aumService.getItems().subscribe();*/



    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        this.aumService.applyQueryParams(params);
      }),
      switchMap(() => {
        return this.aumService.getItems();
      })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.aumService.dataChange.pipe(tap(() => {
      this.aumService.updateQueryParams(this.aumService.filter, this.router, this.route);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.aumService.filter.search = search;
    return this.aumService.runSearch();
  }

  openDialog(event, row): void {
    this.intakeDialog.open(event, row).pipe(
      switchMap(() => this.aumService.getItems())
    ).subscribe();
  }
}
