<mat-sidenav-container class="container">
  <mat-sidenav #filterNav mode="side" opened="false" position="end" class="filter-sidenav">
    <mat-list>
      <mat-list-item>
        <lib-filter-input [(filtered)]="agolitemsService.filter.owner_fk__is_r9_user"
                      (filterCleared)="clearFilter('owner_fk__is_r9_user')"
                      label="R9 Owner" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="agolitemsService.filter.type"
                      (filterCleared)="clearFilter('type')"
                      label="Type" type="choices" [choices]="agolitemsService.itemtypes | async"
                      field_name="type"></lib-filter-input>
      </mat-list-item>
      <mat-list-item matTooltip="Filters to all items shared to the selected group(s)."
                     [matTooltipPosition]="'before'">
        <lib-filter-input [(filtered)]="agolitemsService.filter.groups" field_name="title" value_field="identifier"
                      (filterCleared)="clearFilter('groups')" [choices]="agolitemsService.select_groups | async"
                      label="Group" type="choices"></lib-filter-input>
      </mat-list-item>
      <mat-list-item matTooltip="Filters to all items owned by the selected user(s)." [matTooltipPosition]="'before'">
        <lib-filter-input [(filtered)]="agolitemsService.filter.owner" field_name="username"
                      (filterCleared)="clearFilter('owner')" [choices]="agolitemsService.select_users | async"
                      label="Owner" type="choices"></lib-filter-input>
      </mat-list-item>

      <mat-list-item>
        <lib-filter-input [(filtered)]="agolitemsService.filter.has_mapservice"
                      (filterCleared)="clearFilter('has_agol')"
                      label="Map Service" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="agolitemsService.filter.has_featureclass"
                      (filterCleared)="clearFilter('has_featureclass')"
                      label="Enterprise Library" type="boolean"></lib-filter-input>
      </mat-list-item>
      <mat-list-item>
        <lib-filter-input [(filtered)]="agolitemsService.filter.access" [value_field]="'key'" [field_name]="'value'"
                      (filterCleared)="clearFilter('access')" [choices]="agolitemsService.access_choices | async"
                      label="Access" type="choices"></lib-filter-input>
      </mat-list-item>
      <div style="display: flex; justify-content: space-around">
        <button type="button" (click)="agolitemsService.runSearch()" mat-raised-button
                color="primary">
          Apply
        </button>
        <button type="button" (click)="clearAllFilters()" mat-raised-button color="accent">
          Clear All
        </button>
      </div>
    </mat-list>
  </mat-sidenav>
  <div class="sidenav-content">
    <div class="list-header">
      <mat-form-field style="width:250px;">
        <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
          <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
            (click)="searchInput.setValue(null);"
            *ngIf="agolitemsService.filter.search">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div>
          <button type="button" mat-raised-button (click)="filterNav.toggle()" color="primary">
            {{ filterNav.opened ? 'Hide Filter' : 'Show Filter' }}
          </button>
        </div>
      </div>
      <div class="list-container">
        <mat-table #table [dataSource]="agolitemsService.datasource" class="table" matSort
                   (matSortChange)="agolitemsService.sortTable($event)">
          <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Name of the GeoPlatform item." [matTooltipPosition]="'above'">Title
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell shortname" matTooltip="{{ row.title }}">{{ row.title }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Item Type. Note that links to data and services used in a map are available for web maps but are not available at the web mapping application level."
                           [matTooltipPosition]="'above'">Type
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell">{{ row.type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Owner of the item, indicated by the owner's GeoPlatform username."
                           [matTooltipPosition]="'above'">Owner
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell">{{ row.owner }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="number_of_views">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell checkmark"
                           matTooltip="Total number of times the item has been viewed. The GeoPlatform calculates these statistics based on the total number of times an item's json is called. For example, if an app is opened by a user, the views of both the app and the map used in it will increment."
                           [matTooltipPosition]="'above'">Views
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark"> {{ row.number_of_views }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="map_service_location">
          <mat-header-cell *matHeaderCellDef class="header-cell checkmark" matTooltip="Indicates the ArcGIS Server environment in which the map service was published (Region 9, ER Cloud, NCC), where applicable. Hover over the icon to see details.
            Hover over the icon to see environment details." [matTooltipPosition]="'above'">Map Service
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.map_service_location === 'region_9'" style="color:#202020;" matTooltip="Region 9">
              computer
            </mat-icon>
            <mat-icon *ngIf="row.map_service_location === 'er_cloud'" style="color:#202020;" matTooltip="ER Cloud">
              cloud_queue
            </mat-icon>
            <mat-icon *ngIf="row.map_service_location === 'ncc'" style="color:#202020;" matTooltip="NCC">public
            </mat-icon>
            <mat-icon *ngIf="row.map_service_location === ''" style="color:red;"
                      matTooltip="Item does not use R9, ER, or NCC services.">close
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="library_location">
          <mat-header-cell *matHeaderCellDef class="header-cell checkmark" matTooltip="Indicates if a feature class from an enterprise geodatabase is used in the item, including the
            hosting environment of the geodatabase. Hover over the icon to see environment details."
                           [matTooltipPosition]="'above'">Enterprise Library
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell checkmark">
            <mat-icon *ngIf="row.library_location === 'region_9'" style="color:#202020;" matTooltip="Region 9">
              computer
            </mat-icon>
            <mat-icon *ngIf="row.library_location === 'er_cloud'" style="color:#202020;" matTooltip="ER Cloud">
              cloud_queue
            </mat-icon>
            <mat-icon *ngIf="row.library_location === 'ncc'" style="color:#202020;" matTooltip="NCC">public</mat-icon>
            <mat-icon *ngIf="row.library_location === ''" style="color:red;">close</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="display_access">
          <mat-header-cell *matHeaderCellDef class="header-cell">Access</mat-header-cell>
          <mat-cell class="cell" *matCellDef="let row">{{row.display_access}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fetch_date">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                           matTooltip="Indicates if the most recent status refresh." [matTooltipPosition]="'above'">
            Checked
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell">
            {{ row.fetch_date | date:'MMM d, y, h:mm a' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="open_in_new">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row" class="cell" (click)="$event.stopPropagation()" matTooltip="Open in new tab.">
            <a routerLink="/geoplatformitems/{{row.item_id}}" target="_blank">
              <mat-icon>open_in_new</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer;"
                 routerLink="/geoplatformitems/{{row.item_id}}"></mat-row>
      </mat-table>
      <mat-paginator [length]="agolitemsService.count" [pageSize]="25"
                     [pageSizeOptions]="[10, 25, 50, 100]"
                     (page)="agolitemsService.getPage($event)"
                     [pageIndex]="agolitemsService.filter.page-1"></mat-paginator>
    </div>
  </div>
</mat-sidenav-container>
