import { Injectable } from '@angular/core';
import {BaseService, SearchObject, Response} from '../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../shared/services/loading.service';

export interface ExternalServer {
  id: number;
  organization: string;
  office: string;
  url: string;
  notes: string;
  status: boolean;
}

export interface ExternalServerSearchObject extends SearchObject {
}

@Injectable()
export class ExternalServersService extends BaseService{
  filter: ExternalServerSearchObject;

  constructor(http: HttpClient, loading: LoadingService) {
    super('externalservers', http, loading);
    this.filter = {page: 1};
  }

}
