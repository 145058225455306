<mat-form-field>
  <mat-chip-list #tagList>
    <mat-chip
      *ngFor="let tag of tags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(tag)">
      {{tag}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Keywords"
      #tagInput
      [formControl]="tagsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="tagList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag.name">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
