<div id="mapDiv">

<!--  <div id="zoomButtons" style="z-index: 99999; position: absolute; top:15px; left: 10px;">-->
  <!--<button mat-icon-button (click)="mainMapService.zoomIn()" color="primary"><mat-icon>plus</mat-icon></button>-->
  <!--</div>-->

  <app-add-service-button id="add-to-map-button" (map_services)="mapService.addLayers($event)"></app-add-service-button>
  <app-save-map-button id="save-map-button" [view]="mapService.view" [ngStyle]="{'display': save_button_display}"></app-save-map-button>
</div>

<div *ngIf="loading" style="flex-direction: column; flex: 1; position: absolute; display: flex; padding-top: 15%; justify-content: center;">
    <mat-spinner></mat-spinner>
</div>
