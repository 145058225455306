import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AgolItemsService} from '../services/agolitems.service';
import {LoadingService} from '../shared/services/loading.service';
import {TitleService} from '../services/title.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router} from '@angular/router';
import {FormControl} from '@angular/forms';

import {Subscription} from 'rxjs';
import {debounceTime, map, skip, startWith, switchMap, tap} from 'rxjs/operators';


@Component({
  selector: 'app-geoplatform',
  templateUrl: './geoplatform.component.html',
  styleUrls: ['./geoplatform.component.css']
})
export class GeoplatformComponent implements OnInit, OnDestroy {

  displayedColumns = ['title', 'type', 'owner', 'number_of_views', 'map_service_location', 'library_location',
    'display_access', 'fetch_date', 'open_in_new'];
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);

  constructor(public agolitemsService: AgolItemsService, public loadingService: LoadingService,
              public route: ActivatedRoute, public router: Router, public titleService: TitleService, private location: Location) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('GeoPlatform');
    if (!this.route.snapshot.params.hasOwnProperty('owner_fk__is_r9_user')) {
      this.router.navigate(
        [],
        {relativeTo: this.route, queryParams: {owner_fk__is_r9_user: true}, replaceUrl: true}
      );
    }

    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        this.agolitemsService.applyQueryParams(params);
      }),
      switchMap(() => {
        return this.agolitemsService.getItems();
      })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.agolitemsService.dataChange.pipe(tap(() => {
      this.agolitemsService.updateQueryParams(this.agolitemsService.filter, this.router, this.route);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.agolitemsService.filter.search = search;
    return this.agolitemsService.runSearch();
  }

  clearFilter(field): void {
    delete this.agolitemsService.filter[field];
  }

  clearAllFilters(): void {
    for (const key in this.agolitemsService.filter) {
      if (key !== 'page' && key !== 'page_size' && key !== 'search') {
        delete this.agolitemsService.filter[key];
      }
    }
    this.agolitemsService.getItems().subscribe();
  }
}
