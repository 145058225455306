<h1 mat-dialog-title>Edit tags</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <app-tag-input [form]="formGroup" [tags]="data.tags"></app-tag-input>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-button color="primary" (click)="save()">Save</button>
</div>


