<div class="list-header">
  <mat-form-field style="width:250px;">
    <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
      <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
        (click)="searchInput.setValue(null);"
        *ngIf="externalServers.filter.search">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="list-container">
    <mat-table #table [dataSource]="externalServers.datasource" class="table" matSort
               (matSortChange)="externalServers.sortTable($event)">
      <ng-container matColumnDef="organization">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                       matTooltip="Primary agency of state responsible for the services."
                       [matTooltipPosition]="'above'">Agency or State
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> {{ row.organization }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="office">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                       matTooltip="Specific office within the agency or state organization, where applicable."
                       [matTooltipPosition]="'above'">Office
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell"> {{ row.office }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="site_url">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                       matTooltip="Rest endpoint or data download location."
                       [matTooltipPosition]="'above'">URL
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <a [href]="row.site_url" target="_blank">{{row.site_url}}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <display-check [value]="row.status"></display-check>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="notes">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell"
                       matTooltip="Any additional details about the services."
                       [matTooltipPosition]="'above'">Notes
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        {{row.notes}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef class="header-cell">Edit</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <button mat-icon-button (click)="openExternalServerDialog($event, row)">
          <mat-icon>mode_edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef class="header-cell">Delete</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <button mat-icon-button (click)="delete($event, row)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" style="cursor: pointer;"></mat-row>
  </mat-table>
  <mat-paginator [length]="externalServers.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="externalServers.getPage($event)"
                 [pageIndex]="externalServers.filter.page-1"></mat-paginator>
  <button mat-mini-fab color="primary" style="position: absolute; right: 10px; bottom: 10px;"
          (click)="openExternalServerDialog($event, {})">
    <mat-icon>add</mat-icon>
  </button>
</div>

