import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Intake} from '../services/aum.service';
import {interval, Subject, Subscription} from 'rxjs';
import {filter, flatMap, map, share, switchMap, take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-processing-status',
  templateUrl: './processing-status.component.html',
  styleUrls: ['./processing-status.component.css']
})
export class ProcessingStatusComponent implements OnDestroy, OnInit, OnChanges {
  @Input() intake: Intake;
  @Input() progress: number;
  subscription: Subscription;
  @Output() complete: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    if (this.intake.processing_status === 'processing' || this.intake.processing_status === 'pending') {
      this.subscription = this.listenToStatus(this.intake).subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress && !changes.progress.firstChange && this.subscription === undefined) {
      this.subscription = this.listenToStatus(this.intake).subscribe();
    }
  }

  listenToStatus(item) {
    return interval(10 * 1000).pipe(
      switchMap(() => this.http.get<any>(`/aum/dataintake/${item.id}/get_processing_status`)),
      map(response => {
        item.processing_status = response.status;
        return item.processing_status;
      }),
      filter(status => (status === 'complete' || status === 'error')),
      tap(status => {
        // if (status === 'complete') {
          this.complete.emit();
        // }
      }),
      take(1),
      share());
  }


}
