  <mat-sidenav-container class="container">
    <mat-sidenav #filterNav mode="side" opened="false" position="end" class="filter-sidenav" style="padding: 10px;">
      <mat-list>
        <mat-list-item>
          <lib-filter-input *ngIf="changeLogService.filter_options.hasOwnProperty('analysts')"
            label="Analyst" type="multi-selection" [choices]="changeLogService.filter_options.analysts | async"
            [(filtered)]="changeLogService.filter.analyst__in"
            (filterCleared)="changeLogService.clearFilter('analyst__in')"
            field_name="display" value_field="value">
          </lib-filter-input>
        </mat-list-item>
        <mat-list-item>
          <lib-filter-input *ngIf="changeLogService.filter_options.hasOwnProperty('statuses')"
            label="Status" type="multi-selection" [choices]="changeLogService.filter_options.statuses | async"
            [(filtered)]="changeLogService.filter.status__in"
            (filterCleared)="changeLogService.clearFilter('status__in')"
            field_name="display" value_field="value">
          </lib-filter-input>
        </mat-list-item>
        <br>
        <div style="text-align: center;">
          <button type="button" (click)="changeLogService.runSearch()" mat-raised-button color="primary">
            Apply
          </button>&nbsp;
          <button type="button" (click)="changeLogService.clearAllFilters()" mat-raised-button color="accent">
            Clear All
          </button>
        </div>
      </mat-list>
    </mat-sidenav>
    <div class="sidenav-content">
      <div class="list-header">
        <mat-form-field style="width:250px;">
          <input [formControl]="searchInput" matInput type="text" placeholder="Search"/>
          <button style="position:absolute; top:-5px; right:0px;" type="button" mat-icon-button
            (click)="searchInput.setValue(null);"
            *ngIf="changeLogService.filter.search">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div>
          <button type="button" mat-raised-button (click)="filterNav.toggle()" color="primary">
            {{ filterNav.opened ? 'Hide Filter' : 'Show Filter' }}
          </button>
        </div>
      </div>
      <div class="list-container">
        <mat-table #table [dataSource]="changeLogService.datasource" class="table" matSort
                   (matSortChange)="changeLogService.sortTable($event)">
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" matTooltip="Date of log creation." [matTooltipPosition]="'above'">Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell"> {{ row.date | date: 'shortDate' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" matTooltip="Description of requirements for an item update or new item." [matTooltipPosition]="'above'">Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell"> {{ row.action }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="completion_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" matTooltip="Date the task was completed." [matTooltipPosition]="'above'">Completion Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">
              {{ row.completion_date | date: 'shortDate' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="last_updated">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" matTooltip="Date the log was most recently updated. Field is auto-populated." [matTooltipPosition]="'above'">Last Updated
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">
              {{ row.last_updated | date: 'shortDate' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="feature_class">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell shortname"matTooltip="Item requiring an action. If Item is populated, a change is required on existing data. Click into the record to view full items details and all log entries for the existing item. If Item is blank, a new item is required." [matTooltipPosition]="'above'">Item
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell shortname">
              {{ row.object_display }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="analyst">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" matTooltip="R9TC analyst assigned to the task. To be filled out by WAM." [matTooltipPosition]="'above'">Analyst
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">
              {{ row.analyst | displayValue: (changeLogService.all_analysts | async):'name' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">{{ row.status }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef class="header-cell">Edit</mat-header-cell>
            <mat-cell *matCellDef="let row" class="cell">
              <button mat-icon-button (click)="openChangeLogDialog($event, row)"><mat-icon>mode_edit</mat-icon></button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef class="header-cell">Delete</mat-header-cell>
        <mat-cell *matCellDef="let row" class="cell">
          <button mat-icon-button (click)="delete($event, row)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="row" [ngStyle]="{'cursor': row.object_id ? 'pointer': ''}"
                   routerLink="{{ content_types[row.content_type] }}/{{row.object_id}}?previousState=root.changelog"></mat-row>
        </mat-table>
        <mat-paginator [length]="changeLogService.count" [pageSize]="25"
                       [pageSizeOptions]="[10, 25, 50, 100]"
                       (page)="changeLogService.getPage($event)"
                       [pageIndex]="changeLogService.filter.page-1"></mat-paginator>
        <button mat-mini-fab color="primary" style="position: absolute; right: 10px; bottom: 10px;"
              (click)="openChangeLogDialog($event, {})"><mat-icon>add</mat-icon></button>
      </div>
    </div>
  </mat-sidenav-container>
