import { Component, OnInit } from '@angular/core';
import {TitleService} from "../services/title.service";
import {FeatureclassesService, FeatureClassSearchObject} from '../services/featureclasses.service';
import {Observable} from 'rxjs';
import {AgolItemsService, AgolItemsSearchObject} from '../services/agolitems.service';
import {MapservicesService, MapServiceSearchObject} from '../services/mapservices.service';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  total_feature_classes: Observable<number>;
  total_fc_with_abstract: Observable<number>;
  total_fc_with_uuid: Observable<number>;
  total_fc_with_waf: Observable<number>;
  total_fc_with_edg: Observable<number>;
  total_fc_with_map: Observable<number>;
  total_fc_with_agol: Observable<number>;

  total_agol: Observable<number>;
  total_agol_web_maps: Observable<number>;
  total_agol_with_map: Observable<number>;
  total_agol_with_fc: Observable<number>;

  total_map_services: Observable<number>;
  total_map_with_fc: Observable<number>;
  total_map_with_agol: Observable<number>;
  searchText: string;

  constructor(public titleService: TitleService, public agol: AgolItemsService, public mapServices: MapservicesService,
              public featureClasses: FeatureclassesService, public router: Router) { }

  ngOnInit() {
    this.titleService.setTitle('Dashboard');
    this.total_feature_classes = this.featureClasses.getList({page_size: 1}).pipe(map(response => response.count));
    this.total_fc_with_abstract = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, has_abstract: true}).pipe(map(response => response.count));
    this.total_fc_with_uuid = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, has_md_uuid: true}).pipe(map(response => response.count));
    this.total_fc_with_waf = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, waf_status: true}).pipe(map(response => response.count));
    this.total_fc_with_edg = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, edg_status: true}).pipe(map(response => response.count));
    this.total_fc_with_map = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, has_mapservice: true}).pipe(map(response => response.count));
    this.total_fc_with_agol = this.featureClasses
      .getList(<FeatureClassSearchObject>{page_size: 1, has_agol: true}).pipe(map(response => response.count));

    this.total_agol = this.agol.getList({page_size: 1})
      .pipe(map(response => response.count));
    this.total_agol_web_maps = this.agol.getList(<AgolItemsSearchObject>{page_size: 1, type: 'Web Map'})
      .pipe(map(response => response.count));
    this.total_agol_with_map = this.agol.getList(<AgolItemsSearchObject>{page_size: 1, has_mapservice: true})
      .pipe(map(response => response.count));
    this.total_agol_with_fc = this.agol.getList(<AgolItemsSearchObject>{page_size: 1, has_featureclass: true})
      .pipe(map(response => response.count));


    this.total_map_services = this.mapServices.getList({page_size:1})
      .pipe(map(response => response.count));
    this.total_map_with_fc = this.mapServices.getList(<MapServiceSearchObject>{has_featureclass: true, page_size: 1})
      .pipe(map(response => response.count));
    this.total_map_with_agol = this.mapServices.getList(<MapServiceSearchObject>{has_agol: true, page_size: 1})
      .pipe(map(response => response.count));
  }

  loadSearch() {
    this.router.navigate(['globalsearch'], {queryParams: {search: this.searchText}});
  }

}
