<mat-expansion-panel style="flex:1; margin-left: 15px;">
  <mat-expansion-panel-header>
    <mat-panel-title><b style="font-size: small">Track ongoing updates to datasets</b></mat-panel-title>
    <mat-panel-description>Total: {{ changeLogService.count }}</mat-panel-description>
  </mat-expansion-panel-header>

  <mat-table #table [dataSource]="changeLogService.datasource" class="table" matSort
             (matSortChange)="changeLogService.sortTable($event)">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.date | date: 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="header-cell">Action</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.action }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="comments">
      <mat-header-cell *matHeaderCellDef class="header-cell">Comments</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.comments }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="analyst">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Analyst</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        {{ row.analyst | displayValue: (changeLogService.all_analysts | async):'name'}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="completion_date">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Completion Date</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.completion_date | date: 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="last_updated">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Last Updated</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.last_updated | date: 'shortDate' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef class="header-cell">Delete</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <button mat-icon-button (click)="deleteChangeLog($event, row)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{ row.status }}</mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="['date', 'action', 'status', 'comments', 'analyst', 'completion_date', 'last_updated', 'delete']"
      class="header-row"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: ['date', 'action', 'status', 'comments', 'analyst', 'completion_date', 'last_updated', 'delete']"
      (click)="openChangeLogDialog(row)"></mat-row>
  </mat-table>
  <mat-action-row>
    <button mat-mini-fab color="primary"
            (click)="openChangeLogDialog({object_id: id, content_type: changeLogService.filter.content_type})">
      <mat-icon>add</mat-icon>
    </button>
  </mat-action-row>
</mat-expansion-panel>
