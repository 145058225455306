<div>
  <mat-form-field style="width: 100%">
    <input matInput type="search" placeholder="Search" name="search" [(ngModel)]="map_services.filter.search"
           (keyup.enter)="map_services.runSearch()"/>
  </mat-form-field>
  <div *ngIf="this.urls.length > 0" class="mat-body" style="margin-bottom: 10px;">
    <b>Selected Map Services</b>:
    {{displaySelectServices().join(', ')}}
  </div>
  <!--  <span *ngFor="let service of urls">{{service.name}}</span>-->
</div>

<div class="list-container">
  <mat-table #table [dataSource]="map_services.datasource" class="table">
    <ng-container matColumnDef="checkbox">
      <mat-header-cell *matHeaderCellDef class="header-cell">
<!--       Potentially add this feature later?
 <mat-checkbox matTooltip="Select All"></mat-checkbox>-->
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <mat-checkbox [ngModel]="row.selected" (change)="toggleMapService(row)"></mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell" (click)="toggleMapService(row)">
        <div>{{row.breadcrumbs ? row.breadcrumbs : row.name}}</div>

        <div class="mat-small">{{row.summary ? row.summary : row.description}}</div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['checkbox', 'name']" class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['checkbox', 'name'];" class="row"></mat-row>
  </mat-table>
  <mat-paginator [length]="map_services.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="map_services.getPage($event)"
                 [pageIndex]="map_services.filter.page-1"></mat-paginator>

</div>
<div style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button style="margin-right: 15px;" mat-raised-button mat-dialog-close>Cancel</button>
  <button color="primary" mat-raised-button [mat-dialog-close]="getUrlsList()">Apply</button>

</div>
