import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../shared/services/base.service';
import {LoadingService} from '../../shared/services/loading.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FeatureClass} from '../../services/featureclasses.service';

@Component({
  selector: 'app-metadata-results',
  templateUrl: './metadata-results.component.html',
  styleUrls: ['./metadata-results.component.css']
})
export class MetadataResultsComponent implements OnInit {
  // @Input() feature_class_id: string;
  resultsService: BaseService;
  @Input() feature_class: Observable<FeatureClass>;
  @Input() open: boolean;
  @Input() display_columns = ['description', 'element', 'rule', 'level', 'url'];

  constructor(http: HttpClient, loadingService: LoadingService) {
    this.resultsService = new BaseService('gdbitems/validation_results', http, loadingService);

  }

  ngOnInit() {
    this.feature_class.pipe(
      switchMap(
        fc => {
          this.resultsService.filter = {page: 1, feature_class: fc.uuid};
          return this.resultsService.getItems();
        }
      )).subscribe();
  }

}
