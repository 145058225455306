<div style="display: flex; flex-direction: column; justify-content: space-around">
  <form novalidate [formGroup]="externalServerForm" style="width: 100%;">
    <mat-form-field>
      <input matInput type="text" formControlName="organization" placeholder="Agency or State">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="office" placeholder="Office">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="url" formControlName="site_url" placeholder="URL">
      <mat-error *ngIf="externalServerForm.controls.site_url.hasError('server_error')">
        {{externalServerForm.controls.site_url.getError('server_error')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput matTextareaAutosize formControlName="notes" placeholder="Notes/Comments"></textarea>
    </mat-form-field>
    <div style="display: flex; flex-direction: row; justify-content: flex-end;">
      <button mat-raised-button color="accent" (click)="dialogRef.close(false)" style="margin-right: 15px">Cancel
      </button>
      <button mat-raised-button [disabled]="loading || !externalServerForm.valid" color="primary"
              (click)="save()">Save
      </button>
    </div>
  </form>
</div>
