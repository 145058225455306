import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../services/loading.service';
import {BaseService} from '../services/base.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-service-dialog',
  templateUrl: './add-service-dialog.component.html',
  styleUrls: ['./add-service-dialog.component.css']
})
export class AddServiceDialogComponent implements OnInit {
  map_services: BaseService;
  urls: any[] = [];

  constructor(public dialogRef: MatDialogRef<AddServiceDialogComponent>,
              public http: HttpClient, loading: LoadingService) {
    this.map_services = new BaseService('mapservices', http, loading);

  }

  ngOnInit() {
    this.map_services.getItems().subscribe();
  }

  toggleMapService(map_service) {
    map_service.selected = !map_service.selected;
    if (this.urls.includes(map_service)) {
      this.urls = this.urls.filter(x => x !== map_service);
    } else {
      this.urls.push(map_service);
    }
  }

  displaySelectServices() {
    return this.urls.map(s => s.name);
  }

  getUrlsList() {
    return this.urls.map(s => `${s.root_url}/MapServer`)
  }
}
