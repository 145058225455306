import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {BaseService} from '../../shared/services/base.service';
import {LoadingService} from '../../shared/services/loading.service';

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.css']
})
export class TagDialogComponent implements OnInit {
  indexedFileService: BaseService;
  formGroup = new FormGroup(
    {
      id: new FormControl(),
      tags: new FormControl()
    }
  );

  constructor(public dialogRef: MatDialogRef<TagDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {id: number, tags: string[]},
              public loadingService: LoadingService, public http: HttpClient) {
    this.indexedFileService = new BaseService('aum/indexedfiles', http, loadingService);
  }

  ngOnInit() {
    this.formGroup.setValue({id: this.data.id, tags: this.data.tags});
  }

  save() {
    this.indexedFileService.patch(this.formGroup.value.id, {tags: this.formGroup.value.tags}).pipe(
      tap(() => this.dialogRef.close())
    ).subscribe();
  }
}
