<mat-expansion-panel style="flex:1;">
  <mat-expansion-panel-header>
    <mat-panel-title class="title-column">{{title}}</mat-panel-title>
    <mat-panel-description>Total: {{ aumIndexedFiles.count }}</mat-panel-description>
  </mat-expansion-panel-header>
  <mat-table #table [dataSource]="aumIndexedFiles.datasource" class="table">
    <ng-container matColumnDef="file_name">
      <mat-header-cell *matHeaderCellDef class="header-cell">File Name</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.display_file_name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="item_type">
      <mat-header-cell *matHeaderCellDef class="header-cell">Item Type</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">{{row.item_type}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="download_button">
      <mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <a mat-icon-button matTooltip="Download" [href]="getDownloadURL(row)" target="_self">
          <mat-icon>vertical_align_bottom</mat-icon>
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tagging_button">
      <mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <button mat-icon-button matTooltip="Edit Tags" (click)="openTagDialog(row)">
          <mat-icon>label_important</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="map_service_url">
      <mat-header-cell *matHeaderCellDef class="header-cell">Map Service URL</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <a *ngIf="row.map_service_url" [href]="row.map_service_url" target="_blank">Service URL</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="header-cell">Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="cell">
        <mat-icon *ngIf="row.status === 'completed'" style="color: green;">check</mat-icon>
        <!-- todo: figure out error handling for portal published service -->
<!--        <mat-icon *ngIf="row.mxd_published === 'error'" style="color: red; cursor: pointer"-->
<!--                  matTooltip="Click for details" (click)="showPublishingError(row.display_publishing_results)">close-->
<!--        </mat-icon>-->
        <mat-progress-spinner mode="indeterminate" *ngIf="row.status === 'pending'" [diameter]="25"
                              style="margin-left: 14px;"></mat-progress-spinner>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="display_fields"
                    class="header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: display_fields"></mat-row>
  </mat-table>
  <mat-paginator [length]="aumIndexedFiles.count" [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]"
                 (page)="aumIndexedFiles.getPage($event)"
                 [pageIndex]="aumIndexedFiles.filter.page-1"></mat-paginator>
</mat-expansion-panel>
