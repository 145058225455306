import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../services/title.service';
import {AgolitembackupsService} from '../../services/agolitembackups.service';
import {
  AgolItem,
  AgolItemsSearchObject,
  AgolItemsService,
  Group,
  GroupSearchObject
} from '../../services/agolitems.service';
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationPopupComponent} from '../../confirmation-popup/confirmation-popup.component';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {AgolitedataService, AgolitemData, AgolItemDataSearchObject} from '../../services/agolitedata.service';
import {ServiceLayerSearchObject, ServicelayersService} from '../../services/servicelayers.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'geoplatform-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class GeoPlatformDetailsComponent implements OnInit {
  agolItem: AgolItem = <AgolItem>{};
  item_inline_data: BehaviorSubject<AgolItem[]> = new BehaviorSubject([]);
  item_inline_datasource: InlineDataSource;
  item_inline_filter: AgolItemsSearchObject = {page: 1, page_size: 1000};
  item_inline_count: number = 0;

  item_data: AgolitemData[] = [];
  // data_inline_datasource: InlineDataSource;
  data_inline_filter: AgolItemDataSearchObject = {page: 1, page_size: 1000};
  data_inline_count: number = 0;

  service_inline_data: BehaviorSubject<AgolItem[]> = new BehaviorSubject([]);
  service_inline_datasource: InlineDataSource;
  service_inline_filter: ServiceLayerSearchObject = {page: 1, page_size: 1000};
  service_inline_count: number = 0;


  groups_inline_data: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  groups_inline_datasource: InlineDataSource;
  groups_inline_filter: GroupSearchObject = {page: 1, page_size: 1000};
  groups_inline_count: number = 0;

  constructor(public agolitemsService: AgolItemsService, public route: ActivatedRoute,
              public titleService: TitleService, public agolItemDataService: AgolitedataService,
              public agolitemBackupsService: AgolitembackupsService,
              public daialog: MatDialog, public serviceLayerService: ServicelayersService) {
    this.item_inline_datasource = new InlineDataSource(this.item_inline_data);
    // this.data_inline_datasource = new InlineDataSource(this.data_inline_data);
    this.service_inline_datasource = new InlineDataSource(this.service_inline_data);
    this.groups_inline_datasource = new InlineDataSource(this.groups_inline_data);
  }

  ngOnInit() {
    this.agolitemsService.get(this.route.snapshot.params.id).subscribe(response => {
      this.agolItem = response;
      this.titleService.setTitle(`GeoPlatform Item: ${this.agolItem.title}`);
      this.agolitemBackupsService.filter.item = this.agolItem.item_id;
      this.agolitemBackupsService.getItems().subscribe();

      this.item_inline_filter.data__service_item = this.agolItem.item_id;
      this.agolitemsService.getList(this.item_inline_filter).subscribe(response => {
        this.item_inline_data.next(response.results);
        this.item_inline_count = response.count;
      });

      this.data_inline_filter.item = this.agolItem.item_id;
      this.agolItemDataService.getList(this.data_inline_filter).subscribe(response => {
        // this.data_inline_count = response.count;
        this.item_data = response.results;
      });

      if (this.agolItem.access !== 'private') this.getGroups();

      if (this.agolItem.type === "Web Map") this.service_inline_filter.agolItemData__item__item_id = this.agolItem.item_id;
      else this.service_inline_filter.agolItem__item_id = this.agolItem.item_id;
      this.service_inline_filter.ordering = 'layer_index';
      this.serviceLayerService.getList(this.service_inline_filter).subscribe(response => {
        this.service_inline_count = response.count;
        this.service_inline_data.next(response.results);
      });

    });
  }

  openViewJsonDialog(jsonStr) {
    // let pj = JSON.stringify(JSON.parse(jsonStr),null,2);
    let dialog = this.daialog.open(ConfirmationPopupComponent, {
      width: '900px',
      data: {msg: jsonStr}
    });

    dialog.afterClosed().subscribe(result => {
    });

  }

  getServiceLayers(data) {
    if (data.serviceLayer.length > 0) {
      this.service_inline_filter.agolItemData = data.id;
      // else this.service_inline_filter.agolItem__item_id = this.agolItem.item_id;
      this.serviceLayerService.getList(this.service_inline_filter).subscribe(response => {
        this.service_inline_count = response.count;
        this.service_inline_data.next(response.results);
      });
    }
  }

  getGroups() {
    if (this.agolItem.access !== 'private') {
      this.groups_inline_filter.agol_item = this.agolItem.item_id;
      // else this.service_inline_filter.agolItem__item_id = this.agolItem.item_id;
      this.agolitemsService.get_groups(this.groups_inline_filter).subscribe(response => {
        this.groups_inline_count = response.count;
        this.groups_inline_data.next(response.results);
      });
    }
  }

  toggleIntranetAccess() {
    this.agolitemsService.patch(this.agolItem.item_id, {"allow_public": !this.agolItem.allow_public})
      .subscribe(response => this.agolItem.allow_public = response['allow_public'])
  }

}

class InlineDataSource extends DataSource<any> {
  constructor(private data: BehaviorSubject<any[]>) {
    super();
  }

  connect(): Observable<any[]> {
    return this.data;
  }

  disconnect() {
  }
}
