import {Component, EventEmitter, OnInit} from '@angular/core';
import {BaseService} from '../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from '../../shared/services/loading.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {BreadcrumbService} from '../../shared/services/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {MapService} from '../../shared/services/map.service';
import {IntakeDialogService} from '../services/intake-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {Intake} from '../services/aum.service';
import {TitleService} from '../../services/title.service';

@Component({
  selector: 'aum-data-intake-details',
  templateUrl: './data-intake-details.component.html',
  styleUrls: ['./data-intake-details.component.css']
})
export class DataIntakeDetailsComponent implements OnInit {
  aumService: BaseService;
  aumFilesService: BaseService;
  // aum_intake: Observable<Intake>;
  aum_intake: Subject<Intake> = new Subject<Intake>();
  indexed_files;
  services: ReplaySubject<string[]> = new ReplaySubject<string[]>();
  local_service_endpoint = environment.local_service_endpoint;
  web_map_id: ReplaySubject<string> = new ReplaySubject<string>();
  intakeDialog: IntakeDialogService;
  processing_complete: ReplaySubject<void> = new ReplaySubject<void>();
  private intakeId: string;

  constructor(public http: HttpClient, loading: LoadingService, public route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService,
              dialog: MatDialog, public titleService: TitleService) {
    this.aumService = new BaseService('aum/dataintake', http, loading);
    this.aumFilesService = new BaseService('aum/indexedfiles', http, loading);
    this.web_map_id.next(environment.default_base_map);
    this.intakeDialog = new IntakeDialogService(dialog, http, loading);
  }

  ngOnInit() {
    this.titleService.setTitle('Superfund Intake');
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.intakeId = params.get('id');
        this.getIntake(true).subscribe();
        this.aumFilesService.filter = {page: 1, search: this.intakeId};
        return this.indexed_files = this.aumFilesService.getItems();
      })
    ).subscribe();

  }

  getIntake(updateBreadcrumbs = false) {
    return this.aumService.get(this.intakeId).pipe(
      tap(intake => this.aum_intake.next(intake)),
      filter(() => updateBreadcrumbs),
      tap(intake => this.breadcrumbService.setBreadcrumb([
        {label: 'Home', url: '/home'},
        {label: 'Data Warehouse', url: '/exploredata'},
        {label: `Details: ${intake.item_name}`},
      ]))
    );
  }

  publishMxdsReady(mxds) {
    if (mxds.length > 0) {
      this.services.next(mxds);
    }
  }

  openReport(intake) {
    window.open(`${this.local_service_endpoint}/aum/dataintake/${intake.id}/get_error_report`, '_blank');
  }

  map_service_ready(map_service: MapService) {
    const layers = [];
    let initial_extent;
    // this.services.subscribe(services => {
    //   for (const service of services) {
    //     console.log(service);
    //     // map_service.getLayer()
    //   }
    // });
    map_service.getActiveLayer().subscribe(l => {
      l.forEach(layer => {
        if (!initial_extent) initial_extent = layer.fullExtent;
        else if (layer.fullExtent !== null) initial_extent.union(layer.fullExtent);
      });
      map_service.setExtent(initial_extent);
    });
  }

  processingComplete() {
    this.processing_complete.next();
    this.getIntake().subscribe();
  }

  openIntakeDialog(event, item): void {
    this.intakeDialog.open(event, item).pipe(
      switchMap(() => this.getIntake()),
      tap(() => {
        this.services.next([]);
      })
    ).subscribe();
  }
}
