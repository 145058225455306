import {Injectable, Injector} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import {Observable} from 'rxjs';


import {tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, public router: Router, private tokenExtractor: HttpXsrfTokenExtractor,
              private dialog: MatDialog) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'X-CSRFToken';
    const token = this.tokenExtractor.getToken() as string;
    if (request.url.indexOf(environment.service_url) === 0 || !request.url.includes('http')) {
      // todo: potentially rework https calls to always include service_url?
      const url = request.url.includes('http') ? request.url : `${environment.service_url}${request.url}`;
      request = request.clone({
        headers: token ? request.headers.set(headerName, token) : request.headers,
        url,
        withCredentials: true,
      });
    }

    return next.handle(request).pipe(
      tap(event => {
        },
        err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.dialog.closeAll();
            if (!this.router.url.includes('/login')) {
              this.router.navigate(['/login'], {queryParams: {next: this.router.url}});
            }
          }
        }));
  }
}
