import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {FeatureclassesService} from '../services/featureclasses.service';
import {TitleService} from '../services/title.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, map, skip, startWith, switchMap, tap} from 'rxjs/operators';


@Component({
  selector: 'app-featureclasses',
  templateUrl: './featureclasses.component.html',
  styleUrls: ['./featureclasses.component.css']
})
export class FeatureClassesComponent implements OnInit {

  displayedColumns = ['db_name', 'short_name', 'er_cloud', 'has_abstract', 'has_md_uuid', 'waf_status', 'edg_status',
    'datagov_status', 'has_mapservice', 'has_agol', 'next_update_status', 'access_level', 'time_checked', 'open_in_new'];
  updateStatusChoices = [
    {'display': 'Current', 'value': 'Current'},
    {'display': 'Needs Review', 'value': 'Needs Review'},
    {'display': 'Date Missing', 'value': 'Date Missing'},
  ];
  access_levels = {'internal': 'Internal', 'public': 'Public', 'er_cloud': 'ER Cloud', 'reference': 'Reference'};
  subscription: Subscription;
  queryParamSub: Subscription;
  searchInput = new FormControl(null);

  constructor(public featureClassService: FeatureclassesService, public loadingService: LoadingService,
              public route: ActivatedRoute, public router: Router, public titleService: TitleService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Feature Classes');
    /*if (!this.route.snapshot.queryParams.keepFilter || this.featureClassService.count === undefined) {
      this.featureClassService.filter = {page: 1};
      return this.featureClassService.getItems().subscribe();
    }*/
    this.route.queryParamMap.pipe(map((params: ParamMap) => {
        if (params.has('search') && !this.searchInput.value) {
          this.searchInput.setValue(params.get('search'));
        }
        this.featureClassService.applyQueryParams(params);
    }),
    switchMap(() => {
      return this.featureClassService.getItems();
    })
    ).subscribe();

    this.searchInput.valueChanges.pipe(
      startWith(this.searchInput.value),
      skip(1),
      debounceTime(300),
      tap(searchInput => this.search(searchInput))
    ).subscribe();

    this.queryParamSub = this.featureClassService.dataChange.pipe(tap(() => {
      this.featureClassService.updateQueryParams(this.featureClassService.filter, this.router, this.route);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.queryParamSub.unsubscribe();
  }

  search(search: any): void {
    this.featureClassService.filter.search = search;
    return this.featureClassService.runSearch();
  }

  clearFilter(field): void {
    delete this.featureClassService.filter[field];
    this.featureClassService.updateFilterChoices();
  }

  clearAllFilters(): void {
    for (const key in this.featureClassService.filter) {
      if (key !== 'page' && key !== 'page_size' && key !== 'search') {
        delete this.featureClassService.filter[key];
      }
    }
    this.featureClassService.getItems().subscribe();
  }

}
