import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {TitleService} from '../services/title.service';
import {Router} from '@angular/router';
import {filter, switchMap, tap} from 'rxjs/operators';
import {AuthService, UserConfig, UserConfigService} from 'auth';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'root-state',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {
  expires: Date;
  loading: boolean = false;
  title: Observable<string>;
  config: UserConfig;
  public user: Observable<UserConfig>;
  public admin_url = environment.admin_url;
  public intakes = environment.intakes;
  constructor(public router: Router, public userConfigService: UserConfigService, private authService: AuthService,
              public loadingService: LoadingService, public titleService: TitleService, private browserTitleService: Title) {
  }

  ngOnInit() {
    // this.expires = this.loginService.token_expires;
    if (this.router.url === '') {
      this.router.navigate(['dashboard']);
    }

    this.loadingService.getLoadingStream().subscribe(value => {
      setTimeout(() => this.loading = value);
    });

    this.title = this.titleService.getTitle().pipe(
      tap(title => this.browserTitleService.setTitle(`DMT - ${title}`)),
    );

    this.user = this.userConfigService.config.pipe(
      filter(c => c !== null)
    );
  }

  logout(): void {
    this.authService.logout().pipe(
      switchMap(() => this.router.navigate(['login']))
    ).subscribe();
  }


}
