<mat-toolbar class="header" color="primary" style="justify-content: space-between;">
  <a routerLink="/dashboard" style="text-decoration: none; color: unset">
    <div style="display: flex; align-items: center;">

      <img src="../assets/EPA_logo.png" style="width: 60px;"/>
      Region 9 Data Management Tool
    </div>
  </a>
  <div *ngIf="user | async as u">
    <button mat-button [matMenuTriggerFor]="menu">{{ u.name }}</button>
    <mat-menu #menu="matMenu">
      <a [href]="admin_url" mat-menu-item *ngIf="u.is_superuser">Admin</a>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>
<div *ngIf="!(user | async)"
     style="position: absolute; display: flex; height: 100%; width: 100%; justify-content: center; align-items: center;">
  <mat-spinner></mat-spinner>
</div>
<mat-progress-bar class="main-progress-bar" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-toolbar class="sub-header" color="primary">
  <mat-toolbar-row>
    <button type="button" mat-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    {{ title | async }}
    <span class="spacer"></span>

    <mat-card style="padding: 5px; width: 250px;" *ngIf="router.url !== 'globalsearch'">
      <mat-card-content>
        <input matInput type="search" placeholder="Search" #searchText
               (keyup.enter)="router.navigate(['globalsearch'], {queryParams: {search: searchText.value}}); searchText.value = ''"/>
      </mat-card-content>
    </mat-card>
  </mat-toolbar-row>

</mat-toolbar>
<mat-sidenav-container class="container">
  <mat-sidenav #sidenav mode="side" opened="true" class="sidenav">
    <mat-list *ngIf="user | async as u">
      <a mat-list-item routerLink="/dashboard" routerLinkActive="active" *ngIf="u.is_superuser">
        <mat-icon>dashboard</mat-icon>
        Dashboard
      </a>
      <a mat-list-item routerLink="/featureclasses" routerLinkActive="active"
         *ngIf="userConfigService.checkPermissions('view_gdbitemsextract')">
        <mat-icon>pin_drop</mat-icon>
        Feature Classes
      </a>
      <a mat-list-item routerLink="/geoplatformitems" routerLinkActive="active"
         *ngIf="userConfigService.checkPermissions('view_item')">
        <mat-icon>language</mat-icon>
        GeoPlatform
      </a>
      <a mat-list-item routerLink="/mapservices" routerLinkActive="active"
         *ngIf="userConfigService.checkPermissions('view_mapservice')">
        <mat-icon>layers</mat-icon>
        Map Services
      </a>
      <a mat-list-item routerLink="/externalservers" routerLinkActive="active"
         *ngIf="userConfigService.checkPermissions('view_externalservers')">
        <mat-icon>computer</mat-icon>
        External Sources
      </a>
      <a mat-list-item routerLink="/changelog" routerLinkActive="active"
         *ngIf="userConfigService.checkPermissions('view_changelog')">
        <mat-icon>change_history</mat-icon>
        Change Log
      </a>
      <ng-container *ngFor="let intake of intakes | keyvalue">
        <a mat-list-item [routerLink]="[intake.key.toLowerCase(), 'intake']" routerLinkActive="active"
           *ngIf="userConfigService.checkPermissions('view_dataintake')">
          <mat-icon>water</mat-icon>
          {{intake.value.title}} Intake
        </a>
      </ng-container>
    </mat-list>
  </mat-sidenav>

  <div class="sidenav-content">
    <!--<div>
        <button type="button" mat-raised-button (click)="sidenav.toggle()" color="primary">
        Toggle sidenav
    </button>
    </div>-->
    <router-outlet></router-outlet>
  </div>

</mat-sidenav-container>



